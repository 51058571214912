import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { PostBasicModel, SearchParams } from '@vkph/common/types/models';
import { abstractStorageFactory, buildEndpointWithQueryParams } from '@vkph/common/utils';

export const getSearchPostStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<PostBasicModel>,
    PostBasicModel[],
    PostBasicModel[],
    SearchParams
  >({
    endpointBuilder: ({ search, pageNumber, pageSize }) =>
      buildEndpointWithQueryParams(endpoints.asyncBlogs.entrySearch(), {
        search,
        pageNumber,
        pageSize,
      }),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
