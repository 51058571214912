import { UiSkeleton, UiSpace, UiTypography } from '@vkph/ui';
import React, { ReactNode } from 'react';

import { SkillType, UserProfileModel } from '@vkph/common/types/models';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';

import { skillsTextMap } from '../Skills';

type Props = {
  isOwner: boolean;
  addSkill: () => void;
  userProfile?: UserProfileModel;
  loading: boolean;
  skillType: SkillType;
};

export const SkillsEmpty: React.FC<Props> = (props) => {
  const { isOwner, userProfile, addSkill, loading, skillType } = props;
  const [{ variables: themeVariables }] = useTheme();
  const { spaceS, spaceM } = useSpace();
  const skillsEmptyPadding = usePaddingStyle([spaceS, spaceM]);

  const userFirstName = userProfile?.fullName?.firstName;
  const isUserActive = Boolean(userProfile?.isActive);

  const visitorDescription = `${userFirstName} ещё не добавил(а) ${skillsTextMap[skillType].ManyDown}. Может быть, вы знаете, в чём он(она) силён(сильна)?`;

  const blockedUserDescription = `Здесь ничего нет`;

  const AddLink: React.FC<{ children: ReactNode }> = (addLinkProps) => {
    const { children } = addLinkProps;

    return (
      <UiTypography.Link strong onClick={addSkill}>
        {children}
      </UiTypography.Link>
    );
  };

  return (
    <div
      style={{
        background: themeVariables.colorInput,
        borderRadius: themeVariables.borderRadiusBase,
        ...skillsEmptyPadding,
      }}
    >
      {isOwner && (
        <UiTypography.Text type="secondary">
          <AddLink>Добавьте</AddLink>
          {` ${skillsTextMap[skillType].ManyDown}, чтобы ваши коллеги знали, что вы умеете`}
        </UiTypography.Text>
      )}
      {!isOwner && (
        <UiSkeleton loading={loading} count={3} width="100%">
          <UiTypography.Text type="secondary">
            {isUserActive && (
              <UiSpace direction="vertical">
                {visitorDescription}
                <AddLink>{`Добавить ${skillsTextMap[skillType].OneDown}`}</AddLink>
              </UiSpace>
            )}
            {!isUserActive && blockedUserDescription}
          </UiTypography.Text>
        </UiSkeleton>
      )}
    </div>
  );
};
