import { UiModalBase, UiSpinner } from '@vkph/ui';
import { AxiosResponse } from 'axios';
import { createEffect } from 'effector';
import humps from 'humps';
import { stringify } from 'query-string';
import React, { useCallback, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

import { endpoints } from '@vkph/common/endpoints';
import { useQueryParams } from '@vkph/common/hooks';
import { OAuth2TokenModel } from '@vkph/common/types/models';
import { checkInnerPath, getRoutePath, RouteNames, buildEndpointWithQueryParams } from '@vkph/common/utils';

enum GrantTypes {
  RefreshToken = 'refresh_token',
  AuthorizationCode = 'authorization_code',
}
type GetTokenData = { code: string; redirectUri: string; grantType: GrantTypes.AuthorizationCode };
type RefreshTokenData = { refreshToken: string; clientId: string; grantType: GrantTypes.RefreshToken };

type GetOpenTokenByCodeEffectParams = GetTokenData | RefreshTokenData;

const getOpenTokenByCodeEffect = createEffect<
  GetOpenTokenByCodeEffectParams,
  OAuth2TokenModel,
  AxiosResponse
>(
  (data) =>
    new Promise((resolve) => {
      fetch(`${process.env.REMOTE_URL || ''}${endpoints.profiles.openAPIToken()}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(humps.decamelizeKeys(data)),
      })
        .then((response) => response.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log(result);
          // @ts-expect-error TODO: Fix as
          resolve(humps.camelizeKeys(result as OAuth2TokenModel));
        });
    }),
);

const DEFAULT_REFERRER = getRoutePath(RouteNames.AdminOrgStructure);

export const AdminLoginPage: FC = () => {
  const { queryParams, setQueryParams } = useQueryParams<{
    code: string | null;
    refreshToken?: string | null;
    state: string;
  }>();
  const { state: locationState } = useLocation();
  const referrer = locationState?.referrer || DEFAULT_REFERRER;
  const redirectUri = `${window.location.origin}${window.location.pathname}`;

  const getAuthRedirectCode = useCallback(() => {
    const params = {
      scope: 'userinfo',
      responseType: 'code',
      // clientId: config.OAUTH.CLIENT_ID,
      redirectUri,
      state: stringify({ referrer }),
    };

    window.open(
      buildEndpointWithQueryParams(
        '', // config.OAUTH.URL_LOGIN,
        params,
      ),
      '_self',
    );
  }, [referrer]);

  const removeParams = () =>
    setQueryParams({ code: null, refreshToken: null, state: stringify({ referrer }) });

  const checkParamsBeforeRefer = (params: OAuth2TokenModel) => {
    const { accessToken } = params;

    if (!accessToken) {
      UiModalBase.error({
        title: 'Ошибка получения accessToken',
        okText: 'Повторить',
        onOk: removeParams,
        autoFocusButton: null,
      });
    } else {
      const referrerPathname = checkInnerPath(referrer) ? referrer : DEFAULT_REFERRER;

      window.open([referrerPathname, stringify(params)].join('?'), '_self');
    }
  };

  useEffect(() => {
    if (!queryParams.code && !queryParams.refreshToken) {
      getAuthRedirectCode();
    }

    if (queryParams.code || queryParams.refreshToken) {
      let params = {
        code: queryParams.code,
        grantType: GrantTypes.AuthorizationCode,
        redirectUri,
      } as GetOpenTokenByCodeEffectParams;

      if (queryParams.refreshToken) {
        params = {
          refreshToken: queryParams.refreshToken,
          grantType: GrantTypes.RefreshToken,
          clientId: '', // clientId: config.OAUTH.CLIENT_ID,
        };
      }

      getOpenTokenByCodeEffect(params).then(checkParamsBeforeRefer);
    }
  }, [queryParams]);

  return <UiSpinner spinning />;
};
