// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1770d768_site-navbar-menu .ant-tree-treenode-selected,._1770d768_site-navbar-menu .ant-tree-node-content-wrapper-open{font-weight:400}._1770d768_site-navbar-menu .ant-tree-treenode-selected{border-color:rgba(0,0,0,0)}._1770d768_site-navbar-menu .ant-tree-title{display:inline-flex;flex:auto}._1770d768_site-navbar-menu .ant-tree-switcher{display:none}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/site-navbar-menu/SiteNavbarMenu.scss"],"names":[],"mappings":"AAGI,wHAEE,eAAA,CAGF,wDACE,0BAAA,CAGF,4CACE,mBAAA,CACA,SAAA,CAGF,+CACE,YAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.site-navbar-menu {\n  :global {\n    .ant-tree-treenode-selected,\n    .ant-tree-node-content-wrapper-open {\n      font-weight: 400;\n    }\n\n    .ant-tree-treenode-selected {\n      border-color: transparent;\n    }\n\n    .ant-tree-title {\n      display: inline-flex;\n      flex: auto;\n    }\n\n    .ant-tree-switcher {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"site-navbar-menu": "_1770d768_site-navbar-menu",
	"siteNavbarMenu": "_1770d768_site-navbar-menu"
};
export default ___CSS_LOADER_EXPORT___;
