import { UiPopover, UiButton, UiIcon } from '@vkph/ui';
import React, { useState, FC } from 'react';

import { NotificationFilters, NotificationSortAttributes } from '@vkph/common/types/models';

import { SettingsPopoverContent } from '../settings-popover-content/SettingsPopoverContent';

type Props = {
  icon: SvgrComponent;
  onChange: (filters?: NotificationFilters) => void;
};

export const DrawerPopover: FC<Props> = (props) => {
  const { onChange, icon } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState<NotificationSortAttributes>(NotificationSortAttributes.Date);

  const onSave = () => {
    if (!sortBy) return;

    onChange([sortBy]);
    setIsModalOpen(false);
  };

  return (
    <UiPopover
      destroyTooltipOnHide={{ keepParent: true }}
      title="Сортировка"
      placement="bottomRight"
      content={
        <SettingsPopoverContent onChange={setSortBy} onClose={() => setIsModalOpen(false)} sortBy={sortBy} />
      }
      trigger="click"
      open={isModalOpen}
      onOpenChange={() => setIsModalOpen(!isModalOpen)}
      actions={[
        { type: 'primary', onClick: onSave, size: 'small', label: 'Применить' },
        {
          type: 'tertiary',
          onClick: () => setIsModalOpen(false),
          size: 'small',
          label: 'Отменить',
        },
      ]}
    >
      <UiButton type="link-secondary" icon={<UiIcon component={icon} width={20} height={20} />} />
    </UiPopover>
  );
};
