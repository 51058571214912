import {
  useBreakpoint,
  UiButton,
  UiCheckbox,
  UiForm,
  UiIcon,
  UiSpinner,
  UiTypography,
  UiModalProps,
  UiModal,
  UiSpace,
  UiTooltip,
  message,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageFolderExtensions } from '@vkph/common/store/filestorage';
import {
  FileStorageFolderImageExtensions,
  FileStorageFolderVideoExtensions,
  FileStorageListEntryModel,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import FaqSvg from '@vkph/ui/svg/faq.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type FormValues = {
  extensions: string[];
};

export interface FileListManagerExtensionsBaseProps extends Pick<UiModalProps, 'onClose'> {
  folder: FileStorageListEntryModel;
  tooltipTitle?: ReactNode;
}

export const FileListManagerExtensionBase: FC<FileListManagerExtensionsBaseProps> = (props) => {
  const { tooltipTitle, folder, onClose } = props;
  const [form] = UiForm.useForm<FormValues>();
  const { spaceXS, spaceM } = useSpace();
  const paddingStyle = usePaddingStyle([spaceXS, spaceM]);
  const { lg: isLayoutLarge } = useBreakpoint();
  const imageExtensionsNames = useMemo(() => Object.keys(FileStorageFolderImageExtensions).join(','), []);
  const videoExtensionsNames = useMemo(() => Object.keys(FileStorageFolderVideoExtensions).join(','), []);

  const { storage: fileStorageFolderUserRolesStorage, setFileStorageFolderExtensionsEffect } = useMemo(
    getFileStorageFolderExtensions,
    [],
  );

  const isSetFileStorageFolderExtensionsPending = useStore(setFileStorageFolderExtensionsEffect.pending);

  const { data: dataExtensions, loading: isExtensionsLoading } = useAbstractStorage(
    fileStorageFolderUserRolesStorage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { entryId: folder.id },
      cancelPendingRequestOnUnmount: true,
    },
  );

  const extensions = useMemo<string[]>(() => {
    const extensionsOptionsValue = [];

    if (dataExtensions && dataExtensions.image.length > 0) {
      extensionsOptionsValue.push(imageExtensionsNames);
    }

    if (dataExtensions && dataExtensions.video.length > 0) {
      extensionsOptionsValue.push(videoExtensionsNames);
    }

    return extensionsOptionsValue;
  }, [isExtensionsLoading, dataExtensions]);

  const onFinish = async (formValues: FormValues) => {
    const { extensions: formExtensions } = formValues;
    const videoExtensions = [];
    const imageExtensions = [];

    if (formExtensions.includes(imageExtensionsNames)) {
      imageExtensions.push(...Object.values(FileStorageFolderImageExtensions));
    }

    if (formExtensions.includes(videoExtensionsNames)) {
      videoExtensions.push(...Object.values(FileStorageFolderVideoExtensions));
    }

    try {
      await setFileStorageFolderExtensionsEffect({
        entryId: folder.id,
        video: videoExtensions,
        image: imageExtensions,
      });

      message.success('Ограничения файлов на директорию успешно установлены');
      onClose?.();
    } catch (e) {
      message.error(getErrorResponseMessage(e, 'Не удалось установить ограничения файлов на директорию'));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      extensions,
    });
  }, [extensions]);

  const ButtonsWrapper = () => {
    const buttons = (
      <>
        <UiButton
          block
          disabled={isSetFileStorageFolderExtensionsPending}
          type="primary"
          size="large"
          onClick={form.submit}
          label="Сохранить"
        />
        <UiButton block type="secondary" size="large" onClick={onClose} label="Отмена" />
      </>
    );

    if (isLayoutLarge) {
      return (
        <UiModal.Footer hasTopBorder>
          <UiModal.Footer.Buttons>{buttons}</UiModal.Footer.Buttons>
        </UiModal.Footer>
      );
    }

    return (
      <UiSpace size="middle" direction="vertical" full>
        {buttons}
      </UiSpace>
    );
  };

  return (
    <UiSpinner spinning={isExtensionsLoading}>
      <UiForm form={form} size="small" layout="vertical" onFinish={onFinish}>
        {isLayoutLarge && (
          <UiModal.Header hasBottomBorder>
            <UiModal.Header.Title steps={getModalStepsForSingleTitle('Тип файлов для папки')}>
              <UiTooltip title={tooltipTitle}>
                <UiIcon
                  style={{ marginLeft: spaceXS }}
                  color={variables.themePalette.colorIcon}
                  component={FaqSvg}
                  width={20}
                  height={20}
                />
              </UiTooltip>
            </UiModal.Header.Title>
          </UiModal.Header>
        )}

        <UiModal.Content basePadding>
          <UiSpace size={4} direction="vertical" style={{ marginBottom: spaceM }}>
            <UiTypography.Text type="secondary">Название папки</UiTypography.Text>
            <UiTypography.Title level={3}>{folder.name}</UiTypography.Title>
          </UiSpace>

          <UiForm.Item
            style={{ marginBottom: 0 }}
            name="extensions"
            label="Какие файлы можно загружать в папку:"
          >
            <UiCheckbox.Group>
              <UiSpace style={paddingStyle} direction="vertical" size={spaceM} full>
                <UiCheckbox value={imageExtensionsNames}>
                  Графический файл ({imageExtensionsNames})
                </UiCheckbox>
                <UiCheckbox value={videoExtensionsNames}>Видеофайл ({videoExtensionsNames})</UiCheckbox>
              </UiSpace>
            </UiCheckbox.Group>
          </UiForm.Item>
        </UiModal.Content>

        <ButtonsWrapper />
      </UiForm>
    </UiSpinner>
  );
};
