import { UiButton, UiFlex, UiIcon, UiTypography, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useSpace } from '@vkph/ui/hooks';
import { useTheme, variables } from '@vkph/ui/providers/theme';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';
import CheckboxIndeterminateSvg from '@vkph/ui/svg/checkbox-indeterminate.svg';
import CheckboxUncheckedSvg from '@vkph/ui/svg/checkbox-unchecked.svg';
import CheckboxSvg from '@vkph/ui/svg/checkbox.svg';

import { ControlBarLeftActionProps } from '../ControlBarLeftActions';

export const ControlBarLeftActionsDesktop: FC<ControlBarLeftActionProps> = (props) => {
  const {
    isMultiSelect,
    onCancel,
    isSearching,
    onSelectAll,
    onDeselectAll,
    onMultipleModeClick,
    selectedCount,
    onCreateFolderClick,
    totalCount,
    isMultiSelectPermission,
    isManageFileStorage,
    foundItemsCount,
  } = props;

  const [{ variables: themeVariables }] = useTheme();
  const isAllEntriesSelected = selectedCount === totalCount;
  const { spaceXS } = useSpace();

  const counterData = useMemo(() => {
    if (selectedCount === 0) {
      return {
        icon: CheckboxUncheckedSvg,
        color: variables.themePalette.colorIcon,
      };
    }

    return {
      icon: selectedCount === totalCount ? CheckboxSvg : CheckboxIndeterminateSvg,
      color: themeVariables.colorBrand,
    };
  }, [selectedCount, totalCount]);

  return (
    <UiFlex gap={spaceXS}>
      {!isMultiSelect && !isSearching && isManageFileStorage && (
        <UiButton
          onClick={onCreateFolderClick}
          type="link-secondary"
          icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
          label="Создать папку"
        />
      )}
      {isSearching && <UiTypography.Text strong>Найдено: {foundItemsCount}</UiTypography.Text>}
      {!isMultiSelect && totalCount > 0 && isMultiSelectPermission && isManageFileStorage && (
        <UiButton
          onClick={onMultipleModeClick}
          type="link-secondary"
          icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
          label="Выбрать несколько"
        />
      )}
      {isMultiSelect && totalCount > 0 && (
        <>
          <UiIcon.Label
            type={UiTypographyTextTypes.Primary}
            color={counterData.color}
            component={counterData.icon}
            label={`${selectedCount} из ${totalCount}`}
          />
          <UiButton
            type="link"
            onClick={isAllEntriesSelected ? onDeselectAll : onSelectAll}
            label={`${isAllEntriesSelected ? 'Снять выделение' : 'Выбрать все'}`}
          />
          <UiButton onClick={onCancel} type="link-secondary" label="Отмена" />
        </>
      )}
    </UiFlex>
  );
};
