import { createEvent, createStore } from 'effector';

import { getCommentSearchStorage } from '@vkph/common/store/comments';
import {
  PagesSearchParams,
  ProfileSearchParams,
  SearchAllCategory,
  SearchCategoryType,
  SearchParams,
  SearchSingleCategory,
  SitesSearchParams,
} from '@vkph/common/types/models';
import { AbstractStorage } from '@vkph/common/utils';

import { getSearchBlogStorage } from '../search-blog';
import { getSearchEventStorage } from '../search-event';
import { getSearchFilesStorage } from '../search-files';
import { getSearchListsStorage } from '../search-lists';
import { getSearchNewsStorage } from '../search-news';
import { getSearchPostStorage } from '../search-post';
import { getSearchProfileAdvancedStorage } from '../search-profile-advanced';
import { getSearchSitesStorage } from '../search-sites';
import { getSearchSitesPagesStorage } from '../search-sites-pages';
import { getSearchTagDictStorage } from '../search-tag';
import { SearchCategory } from './constants';

export * from './constants';

export type AdditionalSearchParams = PagesSearchParams & Partial<ProfileSearchParams> & SitesSearchParams;

export interface StartSearchParams extends SearchParams {
  searchCategory: SearchCategoryType;
}

type SearchStatusState = {
  loading: boolean;
  isAnyFetched: boolean;
  isShowEmpty: boolean;
};

type Storage = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storage: AbstractStorage<any, any, any, SearchParams>;
};

type GetSearchStorageParams<T> = {
  storageNames: T[];
  params: {
    searchCategoryAmount: number;
    searchAllAmount?: number;
  };
};

const siteSearchCategories: SearchSingleCategory[] = [
  SearchSingleCategory.News,
  SearchSingleCategory.Files,
  SearchSingleCategory.Pages,
  SearchSingleCategory.Lists,
];

const searchStorages: Record<SearchSingleCategory, () => Storage> = {
  [SearchSingleCategory.Profile]: getSearchProfileAdvancedStorage,
  [SearchSingleCategory.Blog]: getSearchBlogStorage,
  [SearchSingleCategory.News]: getSearchNewsStorage,
  [SearchSingleCategory.Pages]: getSearchSitesPagesStorage,
  [SearchSingleCategory.Sites]: getSearchSitesStorage,
  [SearchSingleCategory.Post]: getSearchPostStorage,
  [SearchSingleCategory.Comment]: getCommentSearchStorage,
  [SearchSingleCategory.Files]: getSearchFilesStorage,
  [SearchSingleCategory.Event]: getSearchEventStorage,
  [SearchSingleCategory.Tag]: getSearchTagDictStorage,
  [SearchSingleCategory.Lists]: getSearchListsStorage,
};

export const getSearchStorage = <T extends SearchSingleCategory>({
  storageNames,
  params,
}: GetSearchStorageParams<T>) => {
  const defaultSearchStatus: SearchStatusState = {
    loading: false,
    isAnyFetched: false,
    isShowEmpty: false,
  };
  const storages = storageNames.map((storageName) => ({
    category: storageName,
    storage: searchStorages[storageName as T](),
  }));

  const searchStatusStore = createStore<SearchStatusState>(defaultSearchStatus);

  const resetStoresEvent = createEvent();
  const startSearchEvent = createEvent<StartSearchParams>();
  const completeSearchEvent = createEvent();
  const additionalSearchParamsStore = createStore<AdditionalSearchParams>({});
  const resetAdditionalSearchParamsEvent = createEvent();
  const setAdditionalSearchParamsEvent = createEvent<AdditionalSearchParams>();

  resetStoresEvent.watch(() => {
    storages.forEach(({ storage }) => {
      storage.storage.cancelPendingRequest();
      storage.storage.resetStoreEvent();
    });
  });

  additionalSearchParamsStore
    .on(setAdditionalSearchParamsEvent, (state, extraParams) => {
      return { ...state, ...extraParams };
    })
    .reset(resetAdditionalSearchParamsEvent);

  startSearchEvent.watch((searchParams) => {
    const { searchCategory, search, pageNumber, pageSize } = searchParams;

    const additionalParams = additionalSearchParamsStore.getState();

    const fetchParams: SearchParams = {
      search,
      pageNumber,
      pageSize: searchCategory === SearchCategory.All ? params.searchAllAmount : pageSize,
      isDraft: searchCategory === SearchSingleCategory.Event ? false : undefined,
      ...additionalParams,
    };

    const fetchArr: Promise<unknown>[] = [];

    storages.forEach(({ storage, category }) => {
      if (
        searchCategory === category ||
        searchCategory === SearchCategory.All ||
        (searchCategory === SearchAllCategory.AllSites && siteSearchCategories.includes(category))
      ) {
        fetchArr.push(storage.storage.fetchEffect(fetchParams));
      }
    });

    Promise.allSettled(fetchArr).then(() => completeSearchEvent());
  });

  searchStatusStore
    .on(startSearchEvent, () => {
      return { loading: true, isAnyFetched: true, isShowEmpty: false };
    })
    .on(completeSearchEvent, (state) => {
      const isShowEmpty = !storages.some(({ storage }) => storage.storage.store.getState().data?.length);

      return { ...state, loading: false, isShowEmpty };
    })
    .reset(resetStoresEvent);

  return {
    searchStorages: storages.reduce(
      (res, curr) =>
        Object.assign(res, {
          [curr.category]: curr.storage,
        }),
      {},
    ) as Record<T, Storage>,
    searchStatusStore,
    resetStoresEvent,
    startSearchEvent,
    additionalSearchParamsStore,
    setAdditionalSearchParamsEvent,
    resetAdditionalSearchParamsEvent,
  };
};
