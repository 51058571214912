import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { NewsArticle, SearchParams } from '@vkph/common/types/models';
import { abstractStorageFactory, buildEndpointWithQueryParams } from '@vkph/common/utils';

export const getSearchNewsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<NewsArticle>,
    NewsArticle[],
    NewsArticle[],
    SearchParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.news.feed(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
