import { useBreakpoint } from '@vkph/ui';
import React, { FC, ReactNode } from 'react';

import { HeaderDesktop } from './desktop';
import { HeaderMobile } from './mobile';

type Props = {
  navbar: ReactNode;
};

export const Header: FC<Props> = (props) => {
  const { navbar } = props;
  const breakpoints = useBreakpoint();

  if (breakpoints.lg) return <HeaderDesktop navbar={navbar} />;

  return <HeaderMobile />;
};
