import {
  UiForm,
  UiFormItemProps,
  UiCheckbox,
  UiCheckboxChangeEvent,
  UiDnD,
  UiFlex,
  UiTruncateMarkup,
  useSpace,
  usePaddingStyle,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { NavigationMenuItem } from '@vkph/modules/types/navigation';

import { MenuLinkTruncated } from '../../menu/link-truncated/MenuLinkTruncated';
import styles from './NavigationFavoritesItem.scss';

export type CheckboxChangeEventHandlerCustom = (e: UiCheckboxChangeEvent, name: string | undefined) => void;

interface Props extends UiFormItemProps<NavigationMenuItem> {
  index: number;
  selectedKeys: string[];
  isFavoritesEdit: boolean;
  isSuccessEditLoading: boolean;
  isDragging: boolean;
  onCheckboxFavoritesChange: CheckboxChangeEventHandlerCustom;
}

export const NavigationFavoritesItem: React.FC<Props> = (props) => {
  const { value, index, selectedKeys, isFavoritesEdit, isSuccessEditLoading, onCheckboxFavoritesChange } =
    props;

  const { spaceL } = useSpace();
  const favoritesItemPadding = usePaddingStyle([0, spaceL]);

  const isSelected = useMemo(
    () => (value?.id ? selectedKeys.includes(value.id) : false),
    [selectedKeys, value],
  );

  const onChange = (name: string | undefined) => (e: UiCheckboxChangeEvent) => {
    onCheckboxFavoritesChange(e, name);
  };

  if (!value) return null;

  if (!isFavoritesEdit) {
    return (
      <UiFlex justify="center" vertical className={classNames(styles.navigationFavoritesItem__container)}>
        <MenuLinkTruncated
          {...value}
          className={classNames(styles.navigationFavoritesItem__link, {
            [styles.navigationFavoritesItem__link_selected]: isSelected,
          })}
        />
      </UiFlex>
    );
  }

  return (
    <UiDnD.Sortable.DragItem
      id={value.id}
      disabled={!isFavoritesEdit}
      className={styles.navigationFavoritesItem}
    >
      <UiFlex
        align="center"
        justify="space-between"
        style={favoritesItemPadding}
        className={styles.navigationFavoritesItem__container}
      >
        <UiFlex style={{ marginLeft: 0 }}>
          <UiForm.Item noStyle name={[index, 'checked']} valuePropName="checked">
            <UiCheckbox
              disabled={isSuccessEditLoading}
              onChange={onChange(value?.url)}
              className={styles.navigationFavoritesItem__checkbox}
            />
          </UiForm.Item>
          {isFavoritesEdit && <UiTruncateMarkup truncate>{value.label}</UiTruncateMarkup>}
        </UiFlex>
        {isFavoritesEdit && <UiDnD.Sortable.DragIcon />}
      </UiFlex>
    </UiDnD.Sortable.DragItem>
  );
};
