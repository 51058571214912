import { UiTreeNode } from '@vkph/ui';

import { SiteNavbarItemId, SiteNavbarItemModel } from '@vkph/common/types/models';

export type SiteNavbarFlatMap = Map<SiteNavbarItemId, SiteNavbarItemModel>;

export const getSiteNavbarFlatMap = (data: SiteNavbarItemModel[]) => {
  const map = new Map<SiteNavbarItemId, SiteNavbarItemModel>();
  const ids: SiteNavbarItemId[] = [];

  const loopItems = (items: SiteNavbarItemModel[]) => {
    items.forEach((item) => {
      map.set(item.id, { ...item, children: [] });
      ids.push(item.id);

      if (item.children) {
        loopItems(item.children);
      }
    });
  };

  loopItems(data);

  return { map, ids };
};

export const getSiteNavbarMenuItems = (
  items: SiteNavbarItemModel[],
  getItemProps: (item: SiteNavbarItemModel) => Partial<UiTreeNode>,
) => {
  const result: UiTreeNode[] = [];

  items.forEach((item) => {
    const { children = [] } = item;
    const { children: extraChildren = [], ...rest } = getItemProps(item);
    const isEmpty = children.length === 0 && extraChildren.length === 0;

    result.push({
      ...rest,
      key: item.id,
      children: getSiteNavbarMenuItems(children, getItemProps).concat(extraChildren),
      isLeaf: isEmpty,
      switcherIcon: () => null,
    });
  });

  return result;
};
