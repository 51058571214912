import {
  Markdown,
  Reactions,
  RouterLink,
  UserAvatar,
  ActionsDropdown,
  ActionsDropdownItem,
} from '@vkph/components';
import { UiButton, UiIcon, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  CommentDetailListModel,
  CommentId,
  CommentPermissions,
  EmojiUuid,
  Gender,
  UserModel,
} from '@vkph/common/types/models';
import {
  RouteNames,
  declenseFirstName,
  getFormattedDateToWord,
  getFullNameWithoutPatronymic,
  getRoutePath,
  parseEntityText,
} from '@vkph/common/utils';
import ReplySvg from '@vkph/ui/svg/reply.svg';

import { CommentInput } from '../comment-input';
import styles from './CommentItem.scss';

type Props = {
  comment: CommentDetailListModel;
  className: string;
  maxLevel?: number | null;
  parentNestLevel?: number;
  parentUser?: UserModel;
  onCommentReply?: (text: string, parentId: CommentId | null) => Promise<unknown>;
  onCommentEdit?: (commentId: CommentId, text: string) => Promise<unknown>;
  onCommentDelete?: (commentId: CommentId) => void;
  onReaction: (commentId: CommentId, emojiUuid?: EmojiUuid) => void;
  loading?: boolean;
  maxNestLevelWithShift: number;
};

export const CommentItem = (props: Props) => {
  const {
    comment,
    className,
    maxLevel = 1,
    maxNestLevelWithShift,
    parentNestLevel = 0,
    parentUser,
    onCommentReply,
    onCommentEdit,
    onCommentDelete,
    onReaction,
    loading,
  } = props;
  const { id, user, text, createdAt, children, reactions, isDeleted } = comment;
  const { textValue: commentText } = useMemo(() => parseEntityText(text), [text]);
  const [isReplyMode, setIsReplyMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const fullName = getFullNameWithoutPatronymic(user);

  const isAccessDeleteComment = comment.permissions.includes(CommentPermissions.DeleteComment);
  const isAccessUpdateComment = comment.permissions.includes(CommentPermissions.UpdateComment);
  const createdAtFormatted = useMemo(() => getFormattedDateToWord({ date: createdAt }), [createdAt]);
  const shouldRenderChildren = maxLevel === null || maxLevel > 1;
  const repliesCount = children.length;
  const currentNestLevel = parentNestLevel + 1;
  const onReplySend = (replyText: string, parentId: number) => {
    if (onCommentReply) {
      onCommentReply(replyText, parentId).then(() => {
        setIsReplyMode(false);
      });
    }
  };

  const shouldShowActions = (isAccessUpdateComment || isAccessDeleteComment) && !isDeleted;

  const dropdownActions = useMemo<ActionsDropdownItem[]>(() => {
    const onDelete = () => {
      if (onCommentDelete) {
        onCommentDelete(id);
      }
    };

    const actions: ActionsDropdownItem[] = [];

    if (isAccessDeleteComment) {
      actions.push({
        label: 'Удалить',
        onClick: onDelete,
      });
    }

    if (isAccessUpdateComment) {
      actions.push({
        label: 'Редактировать',
        onClick: () => setIsEditMode(true),
      });
    }

    return actions;
  }, [isAccessDeleteComment, isAccessUpdateComment, onCommentDelete, id]);

  const onEdit = useCallback(
    (newText: string) => {
      if (onCommentEdit) {
        onCommentEdit(id, newText).then(() => {
          setIsEditMode(false);
        });
      }
    },
    [id, onCommentEdit],
  );

  const isCommentShifted = currentNestLevel <= maxNestLevelWithShift;

  const repliedToWord = parentUser?.gender === Gender.Female ? ' ответила ' : ' ответил ';

  const responderName = useMemo(() => {
    if (!parentUser || !parentUser.firstName) {
      return null;
    }

    return declenseFirstName({ name: parentUser.firstName, gender: parentUser.gender });
  }, [parentUser]);

  const isCommentEdit = isEditMode && onCommentEdit;
  const isActive = comment.user.isActive !== false || comment.user.keycloakUser?.isActive !== false;

  const reactionsProps = useMemo(() => {
    if (isActive) {
      return {
        onReaction: (emojiUuid?: EmojiUuid) => onReaction(id, emojiUuid),
      };
    }

    return null;
  }, [isActive, id]);

  return (
    <div className={className}>
      <div className={styles.commentItem}>
        <div className={styles.commentItem__main}>
          {isDeleted && (
            <div className={styles.commentItem__deletedTextWrapper}>
              <UiTypography.Text className={styles.commentItem__deletedText}>
                Комментарий удален
              </UiTypography.Text>
            </div>
          )}
          {!isDeleted && (
            <>
              <div className={styles.commentItem__header}>
                <Link
                  className={styles.commentItem__avatar}
                  to={getRoutePath(RouteNames.Profile, { id: String(user.keycloakUser?.keycloakId) })}
                >
                  <UserAvatar
                    src={user.avatar}
                    isActive={user.isActive || user.keycloakUser?.isActive}
                    size="middle"
                  />
                </Link>
                <div>
                  <div>
                    <Link
                      className={classNames(styles.commentItem__name, styles.commentItem__nameLink)}
                      to={getRoutePath(RouteNames.Profile, { id: String(user.keycloakUser?.keycloakId) })}
                    >
                      {fullName}
                    </Link>
                    {parentUser && (
                      <>
                        <span className={styles.commentItem__repliedTo}>{repliedToWord}</span>
                        <RouterLink
                          to={getRoutePath(RouteNames.Profile, {
                            id: String(parentUser.keycloakUser?.keycloakId),
                          })}
                        >
                          {responderName}
                        </RouterLink>
                      </>
                    )}
                  </div>
                  <div className={styles.commentItem__date}>{createdAtFormatted}</div>
                </div>
              </div>
              <div className={styles.commentItem__text}>
                {isCommentEdit && (
                  <CommentInput
                    onSend={onEdit}
                    defaultText={parseEntityText(text).textValue}
                    isEditMode={isEditMode}
                    onCancel={() => setIsEditMode(false)}
                    placeholderText="Оставить комментарий"
                  />
                )}
                {!isCommentEdit && <Markdown htmlMarkup>{commentText}</Markdown>}
              </div>
              <div className={styles.commentItem__controls}>
                <div className={styles.commentItem__control}>
                  <UiButton
                    type="link"
                    className={styles.commentItem__replyButton}
                    onClick={() => setIsReplyMode(true)}
                    disabled={!isActive}
                  >
                    <UiTypography.Text className={styles.commentItem__replyButtonText}>
                      Ответить
                    </UiTypography.Text>
                    <UiIcon
                      component={ReplySvg}
                      width={20}
                      height={20}
                      className={styles.commentItem__replyIcon}
                    />
                  </UiButton>

                  {Boolean(repliesCount) && (
                    <span className={styles.commentItem__repliesCount}>{repliesCount}</span>
                  )}
                </div>
                <div className={styles.commentItem__control}>
                  {reactions && <Reactions reactions={reactions} {...reactionsProps} />}
                </div>
              </div>
            </>
          )}
        </div>
        {shouldShowActions && (
          <div className={styles.commentItem__actions}>
            <ActionsDropdown items={dropdownActions} />
          </div>
        )}
      </div>
      {isReplyMode && onCommentReply && (
        <div className={styles.commentItem__replyControl}>
          <CommentInput
            onSend={(replyText) => onReplySend(replyText, id)}
            loading={loading}
            isReplyMode
            onCancel={() => setIsReplyMode(false)}
            autoFocus
            placeholderText="Ответить"
          />
        </div>
      )}
      {shouldRenderChildren &&
        children.map((childComment) => (
          <div
            key={childComment.id}
            className={classNames(styles.commentItem__children, {
              [styles.commentItem__children_shifted]: isCommentShifted,
              [styles.commentItem__children_flat]: !isCommentShifted,
            })}
          >
            <CommentItem
              {...props}
              comment={childComment}
              parentNestLevel={currentNestLevel}
              parentUser={user}
              maxLevel={maxLevel ? maxLevel - 1 : maxLevel}
            />
          </div>
        ))}
    </div>
  );
};
