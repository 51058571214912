import { LinkListItem } from '@vkph/components';
import { UiAvatar, UiButton, UiCell, UiIcon, UiList, UiTabItem, UiTabs } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { getCommentSearchStorage } from '@vkph/common/store/comments';
import { LongreadTypes } from '@vkph/common/types/longread';
import {
  NewsCategoriesDict,
  PostBasicModel,
  OriginListTyping,
  SearchCategoryType,
  NewsArticle,
  PostTypes,
} from '@vkph/common/types/models';
import { declension, memberDeclension, getRoutePath, RouteNames } from '@vkph/common/utils';
import {
  getSearchSitesStorage,
  getSearchBlogStorage,
  getSearchNewsStorage,
  getSearchPostStorage,
  getSearchProfileStorage,
  SearchCategory,
} from '@vkph/modules/store/search';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import ImgPlaceholderSvg from '@vkph/ui/svg/img-placeholder.svg';

import { SearchCategoryResults, SearchCategoryResultsMock } from '../..';
import { PostCompound } from '../../../post/compound';
import { CommentListItem } from '../comment-list-item/CommentListItem';
import { HEADER_SEARCH_CATEGORY_AMOUNT } from '../constants';
import { NewsListItem } from '../news-list-item/NewsListItem';
import styles from './ResultLists.scss';

type Props = {
  originList: OriginListTyping[];
  searchCategory: SearchCategoryType;
  isLoading?: boolean;
  onSearchCategoryChange: (newSearchCategory: SearchCategoryType) => void;
  onShowMoreClick: (listType: SearchCategoryType) => void;
  onClose: () => void;
  searchBlogStorage: ReturnType<typeof getSearchBlogStorage>;
  searchCommentStorage: ReturnType<typeof getCommentSearchStorage>;
  searchPostStorage: ReturnType<typeof getSearchPostStorage>;
  searchProfileStorage: ReturnType<typeof getSearchProfileStorage>;
  newsCategoriesDict: NewsCategoriesDict | null;
  searchNewsStorage: ReturnType<typeof getSearchNewsStorage>;
  searchSitesStorage: ReturnType<typeof getSearchSitesStorage>;
};

const WIDGET_SETTINGS_TOOLBAR_HEIGHT = 40;
const HEADER_HEIGHT = 72;
const DROPDOWN_TABS_HEIGHT = 64;
const DROPDOWN_BOTTOM_OFFSET = 56;

const DROPDOWN_CONTENT_MAX_HEIGHT_STYLE = `calc(100vh - ${
  WIDGET_SETTINGS_TOOLBAR_HEIGHT + HEADER_HEIGHT + DROPDOWN_TABS_HEIGHT + DROPDOWN_BOTTOM_OFFSET
}px)`;

export const ResultLists: React.FC<Props> = (props) => {
  const {
    originList,
    searchCategory,
    isLoading,
    onSearchCategoryChange,
    onShowMoreClick,
    onClose,
    searchBlogStorage,
    searchCommentStorage,
    searchPostStorage,
    searchProfileStorage,
    newsCategoriesDict,
    searchNewsStorage,
    searchSitesStorage,
  } = props;
  const navigate = useNavigate();

  const { data: searchBlogs, pagination: blogsPagination } = useStore(searchBlogStorage.storage.store);
  const { data: searchComments, pagination: commentsPagination } = useStore(
    searchCommentStorage.storage.store,
  );
  const { data: searchPosts, pagination: postsPagination } = useStore(searchPostStorage.storage.store);
  const { data: searchProfiles, pagination: profilesPagination } = useStore(
    searchProfileStorage.storage.store,
  );

  const { data: searchNews, pagination: searchNewsPagination } = useStore(searchNewsStorage.storage.store);
  const { data: searchSites, pagination: searchSitesPagination } = useStore(searchSitesStorage.storage.store);

  const { openLongread } = useLongreadNavigation();
  const { spaceXS, spaceXL } = useSpace();
  const listItemPadding = usePaddingStyle([spaceXS, spaceXL]);

  const blogsList = useMemo(
    () =>
      searchBlogs.map((row) => (
        <LinkListItem
          to={getRoutePath(RouteNames.GroupView, { id: row.slug || row.id })}
          key={row.id}
          avatarProps={{ src: row.fileStorageImageUrl }}
          title={row.name}
          subtitle={`${row.totalParticipants} ${declension(row.totalParticipants, memberDeclension)}`}
          onClick={onClose}
        />
      )),
    [searchBlogs],
  );

  const commentsList = useMemo(
    () =>
      searchComments.map((comment) => (
        <CommentListItem key={comment.id} comment={comment} onClickProfileLink={onClose} />
      )),
    [searchComments],
  );

  const onPostClick = (post: PostBasicModel) => {
    openLongread({ type: LongreadTypes.Post, id: post.id, postType: post.type });
  };

  const postsList = useMemo(() => {
    return (
      <UiList
        split={false}
        dataSource={searchPosts}
        renderItem={(post) => (
          <UiList.Item hoverable style={{ padding: '6px 0' }} onClick={() => onPostClick(post)}>
            <PostCompound.SearchView compact post={post} />
          </UiList.Item>
        )}
      />
    );
  }, [searchPosts]);

  const profilesList = useMemo(
    () =>
      searchProfiles.map((row) => (
        <LinkListItem
          to={getRoutePath(RouteNames.Profile, { id: row.id })}
          key={row.id}
          avatarProps={{ src: row.image }}
          title={row.title}
          isActive={row.isActive}
          subtitle={row.subTitle}
          onClick={onClose}
        />
      )),
    [searchProfiles],
  );

  const onFeedClick = (feed: NewsArticle) => {
    openLongread({
      type: LongreadTypes.News,
      id: feed.id,
      newsType: feed.type || PostTypes.Simple,
    });
  };

  const newsList = useMemo(
    () => (
      <UiList
        split={false}
        dataSource={searchNews}
        renderItem={(feed) => (
          <UiList.Item hoverable style={listItemPadding} onClick={() => onFeedClick(feed)}>
            <NewsListItem newsFeed={feed} newsCategoriesDict={newsCategoriesDict} />
          </UiList.Item>
        )}
      />
    ),
    [searchNews, newsCategoriesDict],
  );

  const siteList = useMemo(() => {
    return (
      <UiList
        split={false}
        dataSource={searchSites}
        renderItem={(site) => (
          <UiList.Item
            style={listItemPadding}
            onClick={() => navigate(getRoutePath(RouteNames.Site, { slug: site.slug }))}
          >
            <UiCell
              title={site.name}
              subtitle={site.description}
              avatar={
                <UiAvatar
                  src={site.avatar}
                  icon={<UiIcon component={ImgPlaceholderSvg} height={40} width={40} />}
                />
              }
              titleProps={{ strong: true }}
            />
          </UiList.Item>
        )}
      />
    );
  }, [searchSites]);

  const contentContainerStyles: React.CSSProperties = {
    maxHeight: DROPDOWN_CONTENT_MAX_HEIGHT_STYLE,
  };

  const advancedSearchBtn = (
    <UiButton
      className={styles.resultList__advancedSearchBtn}
      type="link"
      onClick={() => onShowMoreClick(searchCategory)}
    >
      Расширенный поиск
    </UiButton>
  );

  const commonProps = {
    searchCategory,
    onSearch: onSearchCategoryChange,
  };

  const totalCount = useMemo(() => {
    const paginationArr = [blogsPagination, commentsPagination, postsPagination, profilesPagination];

    return paginationArr.reduce((total: number, { count = 0 }) => total + count, 0);
  }, [blogsPagination, commentsPagination, postsPagination, profilesPagination]);

  const tabItems = useMemo<UiTabItem<string>[]>(() => {
    return originList.map(({ type, title }) => ({ key: type, label: title }));
  }, [originList]);

  const isShowAllShown =
    (totalCount && searchCategory === SearchCategory.All) || totalCount > HEADER_SEARCH_CATEGORY_AMOUNT;

  return (
    <div className={styles.resultList}>
      <UiTabs
        defaultActiveKey={searchCategory}
        activeKey={searchCategory}
        onChange={(key) => {
          onSearchCategoryChange(key as SearchCategoryType);
        }}
        tabBarExtraContent={{
          right: advancedSearchBtn,
        }}
        items={tabItems}
      />
      <div className={styles.resultList__contentContainer} style={contentContainerStyles}>
        <div className={styles.resultList__resultsContainer}>
          {searchProfiles.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Profile}
              pagination={{ total: profilesPagination.count }}
              {...commonProps}
            >
              {profilesList}
            </SearchCategoryResults>
          )}
          {searchBlogs.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Blog}
              pagination={{ total: blogsPagination.count }}
              {...commonProps}
            >
              {blogsList}
            </SearchCategoryResults>
          )}
          {searchPosts.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Post}
              pagination={{ total: postsPagination.count }}
              {...commonProps}
            >
              {postsList}
            </SearchCategoryResults>
          )}
          {searchSites.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Sites}
              pagination={{ total: searchSitesPagination.count }}
              {...commonProps}
            >
              {siteList}
            </SearchCategoryResults>
          )}
          {searchComments.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Comment}
              pagination={{ total: commentsPagination.count }}
              {...commonProps}
            >
              {commentsList}
            </SearchCategoryResults>
          )}
          {searchNews.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.News}
              pagination={{ total: searchNewsPagination.count }}
              {...commonProps}
            >
              {newsList}
            </SearchCategoryResults>
          )}
          {isLoading && <SearchCategoryResultsMock />}
        </div>
        {isShowAllShown && (
          <div className={styles.resultList__showMoreBtnWrapper}>
            <UiButton
              className={styles.resultList__showMoreBtn}
              type="secondary"
              size="large"
              label="Все результаты поиска"
              onClick={() => onShowMoreClick(searchCategory)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
