import { TagMentionSearch } from '@vkph/components';
import { UiBreadcrumbItemType, UiButton, UiIcon, UiNavbar, UiSpace } from '@vkph/ui';
import React, { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TagRelationObjectType } from '@vkph/common/types/models';
import { getBreadcrumbRoutesOverflow } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';
import ArrowBackSVG from '@vkph/ui/svg/arrow-back.svg';
import CloseSvg from '@vkph/ui/svg/close.svg';
import SearchSvg from '@vkph/ui/svg/search.svg';

import { FileListManagerNavigationProps } from '../FileListManagerNavigation';

export const FileListManagerNavigationMobile: FC<FileListManagerNavigationProps> = (props) => {
  const { fileStorageRootId, onCreateFolder, routes, onSearch, onClear, isManageFileStorage } = props;
  const [searchText, setSearchText] = useState('');
  const onClearHandler = () => {
    setSearchText('');
    onClear?.();
  };

  const navigate = useNavigate();

  const breadcrumbRoutes = useMemo<UiBreadcrumbItemType[]>(
    () => getBreadcrumbRoutesOverflow({ routes }),
    [routes],
  );

  const onBack = () => navigate(-1);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const title = isSearchOpen ? (
    <TagMentionSearch
      size="small"
      value={searchText}
      onSearch={onSearch}
      onChange={setSearchText}
      onClear={onClearHandler}
      filters={{
        serviceObjects: [
          {
            objectId: fileStorageRootId,
            objectType: TagRelationObjectType.StorageObject,
          },
        ],
      }}
    />
  ) : (
    breadcrumbRoutes?.[1]?.title || 'Файловое хранилище'
  );

  const extra = useMemo(() => {
    if (isSearchOpen) {
      return (
        <UiButton
          type="link-secondary"
          onClick={() => setIsSearchOpen(false)}
          icon={
            <UiIcon component={CloseSvg} width={20} color={variables.themePalette.colorIcon} height={20} />
          }
        />
      );
    }

    return (
      <UiSpace size="middle">
        {isManageFileStorage && (
          <UiButton
            type="link-secondary"
            onClick={onCreateFolder}
            icon={
              <UiIcon
                component={AddCircleSvg}
                width={20}
                color={variables.themePalette.colorIcon}
                height={20}
              />
            }
          />
        )}
        <UiButton
          type="link-secondary"
          onClick={() => setIsSearchOpen(true)}
          icon={
            <UiIcon component={SearchSvg} width={20} color={variables.themePalette.colorIcon} height={20} />
          }
        />
      </UiSpace>
    );
  }, [isManageFileStorage, isSearchOpen]);

  return (
    <UiNavbar
      extra={extra}
      backIcon={
        <UiButton
          type="link-secondary"
          icon={<UiIcon component={ArrowBackSVG} width={20} height={20} />}
          onClick={onBack}
        />
      }
      title={title}
      bordered={false}
    />
  );
};
