import {
  UiButton,
  UiButtonProps,
  UiBadge,
  BadgeColors,
  UiIcon,
  UiEmpty,
  UiPagination,
  UiBadgeProps,
  UiConfigProviderSizeType,
  UiTypography,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useState, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { notificationsStore } from '@vkph/common/store/notifications';
import { NotificationFetchStatuses, NotificationFilters } from '@vkph/common/types/models';
import { usePagination } from '@vkph/components/hooks';
import { useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import NotificationSvg from '@vkph/ui/svg/notification.svg';
import RankingSvg from '@vkph/ui/svg/ranking.svg';

import { NotificationsDrawer } from '../../notifications-drawer/NotificationsDrawer';

const allNotificationsParams = { status: NotificationFetchStatuses.All };

export const badgeOffsetMap: Record<NonNullable<UiConfigProviderSizeType>, UiBadgeProps['offset']> = {
  large: [8, 0],
  small: [8, -8],
  middle: undefined,
};

export const HeaderNotificationsButton: FC<UiButtonProps> = (props) => {
  const {
    icon = <UiIcon component={NotificationSvg} width={20} height={20} color={variables.themePalette.white} />,
    onClick,
    size = 'middle',
    ...rest
  } = props;

  const {
    pagination: { count },
  } = useStore(notificationsStore.store);

  const { pagination, pageParams, onResetPage } = usePagination({ total: count, pageSize: 20 });

  const location = useLocation();
  const [filters, setFilters] = useState<NotificationFilters>();
  const [isVisible, setIsVisible] = useState(false);
  const { spaceL } = useSpace();

  useEffect(() => setIsVisible(false), [location]);

  const {
    data: notificationsInfo,
    loading: isNotificationsLoading,
    error: isNotificationsError,
  } = useAbstractStorage(notificationsStore, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      ...(filters && { ordering: filters }),
      ...allNotificationsParams,
      ...pageParams,
    },
  });

  const { items: notifications, unreadCount } = notificationsInfo;

  const onInternalClick = (event: MouseEvent<HTMLButtonElement>) => {
    setIsVisible(true);
    notificationsStore.refetchWithLastParams();
    onClick?.(event);
  };

  return (
    <>
      <NotificationsDrawer
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        loading={isNotificationsLoading}
        notifications={notifications}
        drawerTitle="Лента уведомлений"
        icon={RankingSvg}
        emptyFeed={<UiEmpty.Feed emptyMessage={{ header: 'Уведомлений нет' }} />}
        error={isNotificationsError}
        onChange={(sortBy) => {
          if (!sortBy) {
            notificationsStore.refetchWithLastParams();
          }

          setFilters(sortBy);
          onResetPage();
        }}
        markAllAsReadLabel="Отметить все прочитанными"
        unreadCount={unreadCount}
      >
        <UiPagination
          style={{ padding: spaceL, justifyContent: 'center' }}
          {...pagination}
          hideOnSinglePage
          simple
          showSizeChanger={false}
          showTotal={(total) => <UiTypography.Text strong>из {total}</UiTypography.Text>}
        />
      </NotificationsDrawer>
      <UiBadge count={unreadCount} color={BadgeColors.Warning} offset={badgeOffsetMap[size]}>
        <UiButton {...rest} size={size} icon={icon} onClick={onInternalClick} round />
      </UiBadge>
    </>
  );
};
