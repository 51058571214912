import { UiButton, UiForm, message, UiModal, UiSpinner } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback } from 'react';

import {
  getNavigationMenuSettingsStorage,
  updateSettingsNavigationEffect,
} from '@vkph/common/store/navigation';
import {
  NavigationMenuGroup,
  NavigationSettingGroup,
  NavigationSettingGroupItem,
} from '@vkph/common/types/models';
import { getFirstErrorResponseMessage } from '@vkph/components/utils';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import styles from './NavigationEdit.scss';
import { SectionsList } from './sections-list/SectionsList';

type Props = {
  onClose: () => void;
  navigation: NavigationMenuGroup[];
  navigationSettingsStorage: ReturnType<typeof getNavigationMenuSettingsStorage>;
};

export type WidgetDefaultDataNavigation = {
  section: Pick<WidgetDefaultDataNavigation, 'section'>;
  items: NavigationSettingGroupItem[];
};

export type NavigationEditSectionsForm = { sections: NavigationSettingGroup[] };

export const NavigationEdit: FC<Props> = (props) => {
  const { navigation, onClose } = props;

  const [form] = UiForm.useForm<NavigationEditSectionsForm>();

  const isSavePending = useStore(updateSettingsNavigationEffect.pending);

  const onSaveSuccess = useCallback(() => {
    onClose();
    message.success('Сохранено');
  }, [onClose]);

  const onSaveEdit = () => {
    const { sections: data } = form.getFieldsValue();

    updateSettingsNavigationEffect(data)
      .then(onSaveSuccess)
      .catch((e) => message.error(getFirstErrorResponseMessage(e, 'Ошибка сохранения')));
  };

  return (
    <>
      <UiModal.Header className={styles.navigationEdit__header}>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Настройка меню')} />
      </UiModal.Header>

      <UiModal.Content>
        <UiSpinner spinning={isSavePending}>
          <UiForm
            form={form}
            name="navigation-edit-sections"
            initialValues={{ sections: navigation }}
            className={styles.navigationEdit__formContent}
            onFinish={onSaveEdit}
          >
            <SectionsList />
          </UiForm>
        </UiSpinner>
      </UiModal.Content>

      <UiModal.Footer className={styles.navigationEdit__footer}>
        <UiButton
          size="large"
          type="primary"
          label="Сохранить"
          onClick={form.submit}
          loading={isSavePending}
        />
        <UiButton size="large" type="tertiary" label="Отмена" onClick={onClose} />
      </UiModal.Footer>
    </>
  );
};
