import {
  UiFieldNamePath,
  UiForm,
  UiMultiSelect,
  UiMultiSelectItemId,
  UiMultiSelectItemType,
  UiMultiSelectProps,
} from '@vkph/ui';
import React from 'react';

import { BaseDataType, OptionModel } from '@vkph/ui/types/option';

export interface MultiSelectFieldProps<
  ValueType extends UiMultiSelectItemId = UiMultiSelectItemId,
  DataType extends BaseDataType = BaseDataType,
> extends Omit<UiMultiSelectProps, 'items' | 'ItemContent'> {
  value?: OptionModel<ValueType, DataType>[];
  options: OptionModel<ValueType, DataType>[];
  ItemContent?: (props: Omit<UiMultiSelectItemType<ValueType, DataType>, 'ItemContent'>) => JSX.Element;
  name: UiFieldNamePath;
}

export const MultiSelectField = <
  ValueType extends UiMultiSelectItemId = UiMultiSelectItemId,
  DataType extends BaseDataType = BaseDataType,
>(
  props: MultiSelectFieldProps<ValueType, DataType>,
) => {
  const { value = [], name, onChange: _, ...rest } = props;
  const { getFieldValue, setFieldValue } = UiForm.useFormInstance();

  const onItemAdd = (option: OptionModel) => {
    const currentValues = getFieldValue(name) || [];

    setFieldValue(name, [...currentValues, option]);
  };

  const onItemRemove = (optionValue: OptionModel['value']) => {
    setFieldValue(
      name,
      getFieldValue(name).filter((option: OptionModel) => option.value !== optionValue),
    );
  };

  return (
    <UiMultiSelect<ValueType, DataType>
      items={value}
      onItemAdd={onItemAdd}
      onItemRemove={onItemRemove}
      {...rest}
    />
  );
};
