import { UiBreadcrumbItemType, UiSpinner, useBreakpoint } from '@vkph/ui';
import React, { FC, Suspense } from 'react';

import { FileStorageEntryId } from '@vkph/common/types/models';

const FileListManagerDesktop = React.lazy(() => import('./desktop'));
const FileListManagerMobile = React.lazy(() => import('./mobile'));

export enum FileListManagerMultiSelectAction {
  Move = 'Move',
  Delete = 'Delete',
}

export type FileListProps = {
  rootId: FileStorageEntryId;
  rootRoute: UiBreadcrumbItemType;
  folderPath: string;
  filePath: string;
  categoryIds?: string;
  isMultiSelectPermission?: boolean;
  isManageFileStorage?: boolean;
};

export const FileListManager: FC<FileListProps> = (props) => {
  const { lg: isLayoutLarge } = useBreakpoint();

  if (isLayoutLarge) {
    return (
      <Suspense fallback={<UiSpinner spinning />}>
        <FileListManagerDesktop {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<UiSpinner spinning />}>
      <FileListManagerMobile {...props} />
    </Suspense>
  );
};
