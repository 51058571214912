import { Notification } from '@vkph/components';
import { UiAvatarProps, UiSpace, UiTypography, useSpace } from '@vkph/ui';
import { stringify } from 'query-string';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { BlogTabs } from '@vkph/common/types/blogs';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NotificationModel, NotificationTypes, PostBlogModel } from '@vkph/common/types/models';
import {
  RouteNames,
  getFormattedDateToWord,
  getFullNameWithoutPatronymic,
  getPlainTextFromHtml,
  getRoutePath,
  parseMentionsToPlainText,
} from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../../plugins/notification/get-notification-description';
import { isNotificationType } from '../../../../../types/guards/notification-guards';
import { NotificationBlogPostContent } from './content/NotificationBlogPostContent';

type Props = {
  notification: NotificationModel<PostBlogModel>;
};

export const NotificationBlogPost: FC<Props> = (props) => {
  const { notification } = props;
  const {
    createdAt,
    content: { blogs, id: postId, type: postType, createdAt: postCreateAt, body },
    initiator: { fullName, keycloakId, isActive },
    notificationType,
    image: initiatorAvatar,
  } = notification;

  const { space2XS, spaceS } = useSpace();

  const navigate = useNavigate();
  const { openLongread } = useLongreadNavigation();

  const isModeratorDecisionType = [
    NotificationTypes.BlogEntryApproved,
    NotificationTypes.BlogEntryDeclined,
  ].includes(notificationType);

  const {
    name: blogName,
    id: blogId,
    slug: blogSlug,
    fileStorageImageUrl: blogAvatar,
  } = Array.isArray(blogs) ? blogs[0] : blogs;
  const blogRoute = getRoutePath(RouteNames.GroupView, { id: blogSlug || blogId });

  const plainTextContent = body?.data && getPlainTextFromHtml(body.data);
  const postText = (plainTextContent && parseMentionsToPlainText(plainTextContent)) || 'Открыть пост';

  const notificationTitle = isModeratorDecisionType ? blogName : getFullNameWithoutPatronymic(fullName);
  const notificationAvatar = { src: blogAvatar || initiatorAvatar } satisfies UiAvatarProps;
  const notificationHeaderLink = isModeratorDecisionType
    ? blogRoute
    : getRoutePath(RouteNames.Profile, { id: keycloakId });

  const NotificationDescription: FC<{ title: string; subTitle: string }> = (descriptionProps) => {
    const { title, subTitle } = descriptionProps;

    return (
      <UiSpace full direction="vertical">
        <UiTypography.Text type="secondary">{title}</UiTypography.Text>
        <UiSpace full size={space2XS} direction="horizontal">
          <UiTypography.Text type="secondary">{subTitle}</UiTypography.Text>
          <UiTypography.Link
            strong
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={getRoutePath(RouteNames.GroupView, { id: blogSlug || blogId })}
          >
            {blogName}
          </UiTypography.Link>
        </UiSpace>
      </UiSpace>
    );
  };

  const notificationDescription = useMemo(() => {
    const postCreateTime = getFormattedDateToWord({ date: postCreateAt, dateFormat: 'dd MMMM yyyy в HH:mm' });

    if (
      isNotificationType(NotificationTypes.BlogEntryApproved, notificationType) ||
      isNotificationType(NotificationTypes.BlogEntryDeclined, notificationType)
    ) {
      return `${
        isNotificationType(NotificationTypes.BlogEntryApproved, notificationType) ? 'Опубликован' : 'Отклонён'
      } предложенный вами ${postCreateTime} пост:`;
    }

    return <NotificationDescription title={getNotificationDescription(notification)} subTitle="в группе:" />;
  }, [postCreateAt]);

  const onOpenPost = () => openLongread({ type: LongreadTypes.Post, id: postId, postType });
  const onOpenModerationFeed = () =>
    navigate({
      pathname: blogRoute,
      search: stringify({ tab: BlogTabs.Premoderation }),
    });

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={notificationHeaderLink}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={notificationAvatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={spaceS} direction="vertical" full>
          <NotificationBlogPostContent
            visible={isNotificationType(NotificationTypes.BlogEntryApproved, notificationType)}
            content={postText}
            onClick={onOpenPost}
          />
          <NotificationBlogPostContent
            visible={
              isNotificationType(NotificationTypes.BlogEntryCreated, notificationType) ||
              isNotificationType(NotificationTypes.BlogEntryUpdated, notificationType) ||
              isNotificationType(NotificationTypes.BlogEntryUserMentioned, notificationType)
            }
            content={postText}
            onClick={onOpenPost}
          />
          <NotificationBlogPostContent
            visible={isNotificationType(NotificationTypes.BlogEntryDeclined, notificationType)}
            content={postText}
          />
          <NotificationBlogPostContent
            visible={isNotificationType(NotificationTypes.BlogEntryRequireModeration, notificationType)}
            content={postText}
            onClick={onOpenModerationFeed}
          />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
