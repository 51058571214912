import {
  UiFlex,
  UiIcon,
  UiImageBackground,
  UiOverlay,
  UiSpace,
  UiTag,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import React, { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NewsArticle, NewsCategoriesDict, PostTypes } from '@vkph/common/types/models';
import { AbsolutePlacement, useAbsolutePlacementShift, useSpace } from '@vkph/ui/hooks';
import { variables, useTheme } from '@vkph/ui/providers/theme';
import FlameSvg from '@vkph/ui/svg/flame.svg';

interface Props {
  newsArticle: NewsArticle;
  newsCategoriesMap?: NewsCategoriesDict | null;
}

export const NewsPinnedItem: FC<Props> = (props) => {
  const { newsArticle, newsCategoriesMap } = props;
  const { id, categoryId, cover, type, title, important } = newsArticle;
  const newsCategory = categoryId && newsCategoriesMap?.[categoryId];
  const linkProps = useLongreadLink({ type: LongreadTypes.News, id, newsType: type || PostTypes.Simple });
  const { spaceM, spaceS } = useSpace();
  const [{ variables: themeVariables }] = useTheme();

  const backgroundImageStyles: CSSProperties = {
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const { placementCls, placementStyles } = useAbsolutePlacementShift({
    placement: AbsolutePlacement.TopLeft,
    shift: spaceM,
  });

  return (
    <Link tabIndex={-1} {...linkProps}>
      <UiOverlay
        trigger="hover"
        behavior="scale"
        style={{ borderRadius: variables.themeSizes.borderRadiusBase }}
        content={
          <UiFlex style={{ padding: spaceM, height: '100%' }} justify="flex-end" vertical>
            {important && (
              <UiIcon
                component={FlameSvg}
                width={20}
                height={20}
                className={placementCls}
                style={placementStyles}
              />
            )}
            <UiSpace size={spaceS} direction="vertical">
              {newsCategory && (
                <UiTag strong title={newsCategory.name} color={newsCategory.extraInfo.color} />
              )}
              <UiTypography.Title level={3} style={{ color: themeVariables.colorTextOnBrand }}>
                <UiTruncateMarkup lines={3} truncate>
                  {title}
                </UiTruncateMarkup>
              </UiTypography.Title>
            </UiSpace>
          </UiFlex>
        }
      >
        <UiImageBackground style={backgroundImageStyles} url={cover} />
      </UiOverlay>
    </Link>
  );
};
