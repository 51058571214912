import { UiIcon, UiDrawer, UiFlex } from '@vkph/ui';
import React, { FC } from 'react';

import { variables } from '@vkph/ui/providers/theme';
import FaqSvg from '@vkph/ui/svg/faq.svg';

import {
  FileListManagerExtensionBase,
  FileListManagerExtensionsBaseProps,
} from '../FileListManagerExtensionBase';

export const FileListManagerExtensionsMobileDrawer: FC<FileListManagerExtensionsBaseProps> = (props) => {
  const { onClose, folder, tooltipTitle } = props;

  return (
    <UiDrawer
      open={Boolean(folder)}
      onClose={onClose}
      placement="bottom"
      title={
        <UiFlex align="center">
          Тип файлов для папки
          <UiIcon.Hint
            style={{ marginLeft: 8 }}
            color={variables.themePalette.colorIcon}
            component={FaqSvg}
            width={20}
            height={20}
            tooltipProps={{ title: tooltipTitle }}
          />
        </UiFlex>
      }
    >
      <FileListManagerExtensionBase {...props} />
    </UiDrawer>
  );
};
