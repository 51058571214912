import { Layout, UiSpinner, useBreakpoint } from '@vkph/ui';
import React, { FC, Suspense } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';

const FileViewDesktop = React.lazy(() => import('./desktop'));
const FileViewMobile = React.lazy(() => import('./mobile'));

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  fileId: FileStorageEntryId;
  fileStorageRootId?: FileStorageEntryId;
};

export const FileView: FC<Props> = (props) => {
  const { lg: isLayoutLarge } = useBreakpoint();
  const { fileInfoStorage, fileId } = props;

  useAbstractStorage(fileInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      id: fileId,
    },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnChangeParams: { id: fileId },
  });

  return (
    <Layout.Main>
      {isLayoutLarge && (
        <Suspense fallback={<UiSpinner spinning />}>
          <FileViewDesktop {...props} />
        </Suspense>
      )}
      {!isLayoutLarge && (
        <Suspense fallback={<UiSpinner spinning />}>
          <FileViewMobile {...props} />
        </Suspense>
      )}
    </Layout.Main>
  );
};
