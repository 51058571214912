import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { FileStorageListEntryModel, SearchParams } from '@vkph/common/types/models';
import { buildEndpointWithQueryParams } from '@vkph/common/utils';
import { abstractStorageFactory } from '@vkph/common/utils/effector';

export const getSearchFilesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<FileStorageListEntryModel>,
    FileStorageListEntryModel[],
    FileStorageListEntryModel[],
    SearchParams
  >({
    endpointBuilder: ({ search, ...restParams }) =>
      buildEndpointWithQueryParams(endpoints.filestorage.filesSearch(), {
        search,
        ...restParams,
      }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};
