import { Reactions } from '@vkph/components';
import {
  UiCard,
  UiCol,
  UiIcon,
  UiImage,
  UiRender,
  UiRenderType,
  UiRow,
  UiSpace,
  UiTag,
  UiTagTypes,
  UiTruncateMarkup,
  UiTypography,
  useBreakpoint,
} from '@vkph/ui';
import HTMLReactParser from 'html-react-parser';
import React, { FC, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types';
import { NewsArticle, NewsCategoriesDict, PostTypes } from '@vkph/common/types/models';
import { getFormattedDate, getActualEditorFormat, getLineBreakedTextFromHtml } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { variables, useTheme } from '@vkph/ui/providers/theme';
import FlameSvg from '@vkph/ui/svg/flame.svg';
import PinSVG from '@vkph/ui/svg/pin.svg';

import { NewsPostSkeleton } from './skeleton';

type Props = {
  newsCategoriesDist: NewsCategoriesDict | null;
  newsFeed: NewsArticle;
  linkProps?: LinkProps;
};

type NewsPostComposition = {
  Skeleton: typeof NewsPostSkeleton;
};

export const NewsPost: FC<Props> & NewsPostComposition = (props) => {
  const { newsCategoriesDist, newsFeed, linkProps } = props;
  const {
    id,
    type,
    categoryId,
    cover,
    title,
    pinned,
    publishedAt,
    important,
    body,
    viewsCount,
    tags,
    settings,
  } = newsFeed;
  const { isReacted, isComments } = settings;
  const [{ variables: themeVariables }] = useTheme();
  const breakpoints = useBreakpoint();

  const { spaceM, spaceXL, spaceS } = useSpace();
  const { to, state } = useLongreadLink({ type: LongreadTypes.News, id, newsType: type || PostTypes.Simple });

  const plainBodyText = useMemo(() => {
    const actualBodyFormat = getActualEditorFormat(body);

    return getLineBreakedTextFromHtml(actualBodyFormat.data);
  }, [body]);

  const reactions = isReacted ? newsFeed.reactions : undefined;
  const comments = isComments ? newsFeed.commentsCount : undefined;

  return (
    <Link tabIndex={-1} to={to} state={state} {...linkProps}>
      <UiCard size="default" styles={{ body: { padding: breakpoints.lg ? spaceXL : spaceM } }}>
        <UiCard.Content>
          <UiRow gutter={[spaceXL, spaceM]}>
            <UiCol span={24} lg={10}>
              <UiImage src={cover} height="100%" />
            </UiCol>

            <UiCol span={24} lg={14}>
              <UiRow
                gutter={[0, spaceM]}
                justify="space-between"
                style={{ flexDirection: 'column', height: '100%' }}
                wrap={false}
              >
                <UiCol>
                  <UiSpace size={spaceS} direction="vertical" full>
                    <UiRow gutter={spaceM}>
                      <UiCol flex={1}>
                        <UiSpace align="center" size={spaceS}>
                          {pinned && (
                            <UiIcon
                              component={PinSVG}
                              width={20}
                              height={20}
                              style={{ color: variables.themePalette.colorIcon }}
                            />
                          )}
                          {publishedAt && (
                            <UiTypography.Text strong type="secondary">
                              {getFormattedDate(publishedAt)}
                            </UiTypography.Text>
                          )}
                          <UiTag
                            strong
                            size={spaceS}
                            shift={6}
                            title={categoryId ? newsCategoriesDist?.[categoryId]?.name : undefined}
                            color={
                              categoryId ? newsCategoriesDist?.[categoryId]?.extraInfo?.color : undefined
                            }
                            type={UiTagTypes.Dot}
                          />
                        </UiSpace>
                      </UiCol>

                      {important && (
                        <UiCol>
                          <UiIcon component={FlameSvg} width={20} height={20} />
                        </UiCol>
                      )}
                    </UiRow>

                    <UiSpace size={0} direction="vertical">
                      <UiTypography.Title level={2}>
                        <UiTruncateMarkup truncate lines={2}>
                          {title}
                        </UiTruncateMarkup>
                      </UiTypography.Title>

                      {plainBodyText && (
                        <UiTypography.Text style={{ marginBottom: spaceM }} type="secondary">
                          <UiTruncateMarkup truncate lines={4}>
                            {HTMLReactParser(plainBodyText)}
                          </UiTruncateMarkup>
                        </UiTypography.Text>
                      )}
                    </UiSpace>
                  </UiSpace>
                </UiCol>
                <UiCol>
                  <UiSpace size="middle" direction="vertical" full>
                    <UiRender
                      type={UiRenderType.NullElement}
                      visible={Boolean(reactions || comments || viewsCount)}
                    >
                      <Reactions reactions={reactions} comments={comments} views={viewsCount} stretch />
                    </UiRender>
                    <UiRender type={UiRenderType.NullElement} visible={tags.length > 0}>
                      <UiTag.Group tagProps={{ color: themeVariables.colorBrand16 }} tags={tags} />
                    </UiRender>
                  </UiSpace>
                </UiCol>
              </UiRow>
            </UiCol>
          </UiRow>
        </UiCard.Content>
      </UiCard>
    </Link>
  );
};

NewsPost.Skeleton = NewsPostSkeleton;
