import { UiFlex, UiSkeleton, usePaddingStyle, useSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { createArrayMock } from '@vkph/common/utils';

export const SiteNavbarMenuSkeleton: FC = () => {
  const { space2XS, spaceL } = useSpace();
  const { padding } = usePaddingStyle([space2XS, spaceL]);

  return (
    <UiFlex vertical>
      {createArrayMock(6, (_, index) => (
        <UiFlex key={index} style={{ padding, height: 40 }} align="center">
          <UiSkeleton loading width={200} height={20} />
        </UiFlex>
      ))}
    </UiFlex>
  );
};
