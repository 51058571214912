import {
  MAX_BADGE_COUNT,
  UiBadge,
  UiButton,
  UiCard,
  UiFlex,
  UiPagination,
  UiPaginationProps,
  UiRow,
  UiSpace,
  UiTypography,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { SearchCategoryType, SearchSingleCategory } from '@vkph/common/types/models';
import { usePagination } from '@vkph/components/hooks';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { SEARCH_CATEGORY_TITLE, SearchCategory } from '../../../../store/search/advanced-search/constants';

interface Props {
  category: SearchSingleCategory;
  searchCategory: SearchCategoryType;
  isAdvanced?: boolean;
  maxCount?: number;
  onSearch?: (category: SearchCategoryType, page?: number, pageSize?: number) => void;
  pagination?: UiPaginationProps;
}

export const SearchCategoryResults: FC<PropsWithChildren<Props>> = (props) => {
  const {
    category,
    searchCategory,
    isAdvanced,
    pagination: paginationProp,
    maxCount,
    children,
    onSearch,
  } = props;

  const { pagination } = usePagination({
    total: paginationProp?.total,
    pageSizeOptions: paginationProp?.pageSizeOptions,
    showSizeChanger: paginationProp?.showSizeChanger,
    ...paginationProp,
  });
  const { spaceXS, spaceS, spaceM, spaceL, spaceXL } = useSpace();
  const { lg: isLayoutLarge } = useBreakpoint();
  const headerPadding = usePaddingStyle([0, spaceXL, spaceXS]);
  const cardPadding = usePaddingStyle(isAdvanced ? [spaceXL, 0] : [spaceL, 0, spaceM]);
  const showAllButtonPadding = usePaddingStyle([spaceL, spaceXL, 0]);
  const paginationPadding = usePaddingStyle([spaceS, spaceXL, 0]);

  const { onChange } = pagination;

  const onChangePagination: UiPaginationProps['onChange'] = (page, pageSize) => {
    onSearch?.(category, page, pageSize);
    onChange(page, pageSize);
  };

  const handleSearch = () => onSearch?.(category);

  const shownCount = searchCategory !== SearchCategory.All || !isLayoutLarge ? pagination?.total : 0;
  const isMoreRecordsThanMaxCount = maxCount && pagination?.total && pagination.total > maxCount;
  const isShowAllShown =
    isAdvanced && isLayoutLarge && isMoreRecordsThanMaxCount && searchCategory === SearchCategory.All;

  const categoryTitle = (
    <UiTypography.Title level={isAdvanced ? 2 : 3} style={{ margin: 0 }}>
      {SEARCH_CATEGORY_TITLE[category]}
    </UiTypography.Title>
  );
  const categoryBadgeCount = <UiBadge count={shownCount} overflowCount={MAX_BADGE_COUNT} />;
  const isShowLinkToAll = (!isAdvanced || !isLayoutLarge) && searchCategory === SearchCategory.All;
  const categoryTitleInfo = (
    <>
      {!isLayoutLarge && (
        <UiSpace size={spaceXS}>
          {categoryTitle}
          {categoryBadgeCount}
        </UiSpace>
      )}

      {isLayoutLarge && categoryTitle}
      {isLayoutLarge && isAdvanced && categoryBadgeCount}
    </>
  );

  return (
    <UiCard
      emptyRadius={!isAdvanced}
      size="default"
      emptyPadding
      style={{ ...cardPadding, position: 'initial' }}
    >
      <UiFlex vertical>
        <UiFlex justify="space-between" gap={spaceXS} align="center" style={headerPadding}>
          {categoryTitleInfo}
          {isShowLinkToAll && (
            <UiButton size="middle" type="link" onClick={handleSearch} tabIndex={-1} label="Все" />
          )}
        </UiFlex>

        {children}

        {isShowAllShown && (
          <UiRow style={showAllButtonPadding}>
            <UiButton type="secondary" size="large" label="Показать все" onClick={handleSearch} />
          </UiRow>
        )}

        {isAdvanced && searchCategory !== SearchCategory.All && (
          <UiPagination
            style={paginationPadding}
            responsive
            showLessItems={!isLayoutLarge}
            {...pagination}
            onChange={onChangePagination}
          />
        )}
      </UiFlex>
    </UiCard>
  );
};
