import { UiCard, UiMenu, UiMenuItemType, UiMenuSelectEventHandler, UiTypography } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageFolderCategoriesStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';

type Props = {
  entryId: FileStorageEntryId;
  onSelect?: UiMenuSelectEventHandler;
  onDeselect?: UiMenuSelectEventHandler;
  selectedCategoryIds?: string;
};

export const CATEGORIES_ALL_KEY = 'CATEGORIES_ALL_KEY';

const CATEGORIES_ALL = { label: 'Все', key: CATEGORIES_ALL_KEY } satisfies UiMenuItemType;

export const FileStorageCategoryMenu: FC<Props> = (props) => {
  const { entryId, onSelect, onDeselect, selectedCategoryIds } = props;
  const selectedCategories = selectedCategoryIds?.split(',');
  const activeCategories =
    selectedCategories && selectedCategories.length > 0 ? selectedCategories : [CATEGORIES_ALL_KEY];

  const fileStorageCategoriesStorage = useMemo(getFileStorageFolderCategoriesStorage, []);

  const { data: fileStorageCategoryData } = useAbstractStorage(fileStorageCategoriesStorage.storage, {
    autoFetchParams: { entryId },
    autoFetchAndRefetch: Boolean(entryId),
    cancelPendingRequestOnUnmount: true,
  });

  const categoriesMenuItems = useMemo<UiMenuItemType[]>(() => {
    return [
      CATEGORIES_ALL,
      ...fileStorageCategoryData.map((entry) => ({
        key: String(entry.id),
        label: entry.name,
      })),
    ];
  }, [fileStorageCategoryData]);

  return (
    <UiCard size="default" emptyPadding>
      <UiCard.Header>
        <UiTypography.Title style={{ marginBottom: 0 }} level={3}>
          Категории
        </UiTypography.Title>
      </UiCard.Header>
      <UiCard.Content>
        <UiMenu
          multiple
          style={{ marginBottom: 24 }}
          items={categoriesMenuItems}
          onSelect={onSelect}
          onDeselect={onDeselect}
          defaultSelectedKeys={[String(categoriesMenuItems[0].key)]}
          selectedKeys={activeCategories}
        />
      </UiCard.Content>
    </UiCard>
  );
};
