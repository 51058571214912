import { ActionsDropdown, ActionsDropdownItem } from '@vkph/components';
import { UiIcon, useBreakpoint } from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { populatedLayoutStorage } from '@vkph/common/store/populated-layout';
import { RouteNames, authService, isPathMatchRoute, getRoutePath } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import MeatballSvg from '@vkph/ui/svg/meatball.svg';

export const HeaderActions: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const breakpoints = useBreakpoint();
  const isHomePage = isPathMatchRoute(pathname, RouteNames.Home);

  const getPopupContainer = (triggerNode: HTMLElement) => {
    return triggerNode.closest('header')!;
  };

  const items = useMemo(() => {
    const actions: ActionsDropdownItem[] = [
      { label: 'Выйти из профиля', onClick: () => authService.logout() },
    ];

    if (breakpoints.lg) {
      actions.unshift({
        label: 'Настройки',
        onClick: () => navigate(getRoutePath(RouteNames.Settings)),
      });
    }

    if (isHomePage && breakpoints.lg) {
      actions.unshift({
        label: 'Редактировать виджеты',
        onClick: () => populatedLayoutStorage.setIsEditLayoutEvent(),
      });
    }

    return actions;
  }, [isHomePage, breakpoints.lg]);

  return (
    <ActionsDropdown
      getPopupContainer={getPopupContainer}
      items={items}
      dropdownButtonProps={{
        size: 'small',
        round: true,
        icon: <UiIcon component={MeatballSvg} width={20} height={20} color={variables.themePalette.white} />,
      }}
    />
  );
};
