import { ErrorBoundary } from '@vkph/components';
import {
  UiButton,
  UiDrawer,
  UiDrawerProps,
  UiIcon,
  UiRow,
  UiSpace,
  UiTooltip,
  useBreakpoint,
} from '@vkph/ui';
import { AxiosError } from 'axios';
import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationsInfo } from '@vkph/common/store/notifications';
import { markAllNotificationsRead } from '@vkph/common/store/notifications/api';
import { NotificationContent, NotificationFilters, NotificationModel } from '@vkph/common/types/models';
import { createArrayMock, getRoutePath, RouteNames } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { useTheme, variables } from '@vkph/ui/providers/theme';
import GearSvg from '@vkph/ui/svg/gear.svg';

import styles from './NotificationsDrawer.scss';
import { DrawerClose } from './close/DrawerClose';
import { FeedError } from './feed-error/FeedError';
import { NotificationLoading } from './loading/NotificationLoading';
import { NotificationCard } from './notification-card/NotificationCard';
import { DrawerPopover } from './popover/DrawerPopover';
import { DrawerTitle } from './title/DrawerTitle';

const MOCKS_LENGTH = 5;
const notificationsFeedMocks = createArrayMock(MOCKS_LENGTH, (_, key) => <NotificationLoading key={key} />);

type Props = {
  visible: boolean;
  onClose: () => void;
  loading: boolean;
  notifications: NotificationModel<NotificationContent>[];
  drawerTitle: string;
  // notificationTitleType: NotificationTitleTypes;
  // notificationBodyType: NotificationBodyTypes;
  icon: SvgrComponent;
  emptyFeed: React.ReactNode;
  onChange: (filters?: NotificationFilters) => void;
  error: AxiosError | null;
  markAllAsReadLabel: string;
  unreadCount: NotificationsInfo['unreadCount'];
};

export const NotificationsDrawer: FC<PropsWithChildren<Props>> = (props) => {
  const {
    visible,
    onClose,
    loading,
    notifications,
    drawerTitle,
    icon,
    emptyFeed,
    onChange,
    error,
    markAllAsReadLabel,
    children,
    unreadCount,
  } = props;
  const isEmptyFeed = !loading && !error && notifications?.length === 0;
  const navigate = useNavigate();
  const { spaceM, spaceL, spaceXL, space4XL } = useSpace();
  const { padding } = usePaddingStyle([0, spaceL]);
  const [{ variables: themeVariables }] = useTheme();
  const { lg: isLayoutLarge } = useBreakpoint();
  const emptyPaddingStyle: CSSProperties = { padding: 0 };
  const bodyStyle: UiDrawerProps['styles'] = isEmptyFeed
    ? { body: { ...emptyPaddingStyle, background: themeVariables.colorInput } }
    : { body: { ...emptyPaddingStyle } };
  const contentWrapperStyle = isLayoutLarge ? { top: variables.layoutSizes.headerHeight } : undefined;
  const onNavigateNotificationSettings = () => navigate(getRoutePath(RouteNames.SettingsNotifications));

  return (
    <UiDrawer
      placement="right"
      closable={false}
      maskClosable
      onClose={onClose}
      open={visible}
      // footer={<DrawerFooter />} // TODO: Раздел в разработке
      footer={children}
      width={400}
      styles={{
        ...bodyStyle,
        header: { padding },
        footer: emptyPaddingStyle,
        wrapper: contentWrapperStyle,
      }}
      title={<DrawerTitle title={drawerTitle} />}
      className={styles.notificationsDrawer}
      extra={<DrawerClose onClose={onClose} />}
    >
      <ErrorBoundary>
        {!isEmptyFeed && (
          <UiRow
            style={{
              paddingLeft: spaceXL,
              paddingRight: spaceM,
              height: space4XL,
              background: themeVariables.colorInput,
              borderBottom: themeVariables.spacerBorder,
            }}
            justify="space-between"
            align="middle"
          >
            <span>
              <UiButton
                type="link-secondary"
                className={styles.notificationsDrawer__markAsRead}
                onClick={() => markAllNotificationsRead().then(() => onChange())}
                disabled={!unreadCount || loading}
                label={markAllAsReadLabel}
              />
            </span>
            <UiSpace size={spaceXL}>
              <UiTooltip title="Настройки" placement="top">
                <UiButton
                  type="link-secondary"
                  onClick={onNavigateNotificationSettings}
                  icon={<UiIcon component={GearSvg} width={20} height={20} />}
                />
              </UiTooltip>
              <DrawerPopover icon={icon} onChange={onChange} />
            </UiSpace>
          </UiRow>
        )}
        {loading && notificationsFeedMocks}
        {isEmptyFeed && emptyFeed}
        {error && <FeedError />}
        {!loading &&
          !error &&
          notifications.map((notification) => (
            <NotificationCard key={notification.id} notification={notification} />
          ))}
      </ErrorBoundary>
    </UiDrawer>
  );
};
