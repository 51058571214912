import {
  ExternalContentModel,
  NotificationContent,
  NotificationContentV1,
  NotificationModel,
  NotificationTypes,
  NotificationVersions,
} from '@vkph/common/types/models';

export const isNotificationType = (type: NotificationTypes, possibleType: NotificationTypes) =>
  type === possibleType;

export const isNotificationSomeOfTypes = <Type extends NotificationContent>(
  notification: NotificationModel<NotificationContent>,
  types: NotificationTypes[],
): notification is NotificationModel<Type> =>
  types.some((possibleType) => isNotificationType(notification.notificationType, possibleType));

export const isNotificationOld = (
  notification: NotificationModel<NotificationContent>,
): notification is NotificationModel<NotificationContentV1> => {
  return notification.version === NotificationVersions.V1;
};

export const isNotificationExternal = (
  notification: NotificationModel<NotificationContent>,
): notification is NotificationModel<ExternalContentModel> => {
  return 'externalId' in notification.content;
};
