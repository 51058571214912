import { UploadDraggerArea } from '@vkph/components';
import {
  UiButton,
  UiCard,
  UiCollapse,
  UiCollapseExpandIcon,
  UiEmpty,
  UiIcon,
  UiSkeleton,
  UiSpace,
  UiUploadRequestOption,
  message,
  useBreakpoint,
  UiCollapseProps,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { CommentListStorage, getCommentListStorage } from '@vkph/common/store/comments';
import {
  ChangeCommentsCountType,
  FileStorageApiVersions,
  GetFileStorageEntryInfoStorage,
  createUpdateFileStorageEntryEffect,
  getFileStorageEntryVersionListStorage,
  uploadFileStorageAttachmentEffectFactory,
} from '@vkph/common/store/filestorage';
import {
  CommentContentTypes,
  CommentId,
  FileStorageEntryId,
  FileStorageEntryRevision,
  FileStorageEntryVersionId,
  PreloadedFileModel,
} from '@vkph/common/types/models';
import {
  FileSizeUnit,
  FileUploadAccepts,
  checkFileSizeUploadEffect,
  getErrorResponseMessage,
} from '@vkph/common/utils';
import { usePagination } from '@vkph/components/hooks';
import { useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import AddSquareOutlineSvg from '@vkph/ui/svg/add-square-outline.svg';
import RemoveSquareOutlineSvg from '@vkph/ui/svg/remove-square-outline.svg';

import { CommentInput } from '../../comment/comment-input';
import { CommentListStandalone, CommentListStandaloneProps } from '../../comment/comment-list-standalone';
import { fileStorageEntryHistoryStorage } from '../history';
import { FileVersionsPanelExtra } from './panel/extra';
import { FileVersionsPanelHeader } from './panel/header';

type Props = {
  id: FileStorageEntryId;
  fileInfoStorage: GetFileStorageEntryInfoStorage;
};

export const FileVersions: FC<PropsWithChildren<Props>> = (props) => {
  const { id, fileInfoStorage } = props;

  const { data: fileInfoData } = useStore(fileInfoStorage.storage.store);

  const uploadAttachmentEffect = useMemo(() => {
    return uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>();
  }, []);
  const isCreateFilePending = useStore(uploadAttachmentEffect.pending);

  const { pagination, pageParams, onResetPage } = usePagination({
    pageSize: 4,
    current: 1,
  });
  const { spaceM } = useSpace();
  const { lg: isLayoutLarge } = useBreakpoint();

  const {
    storage: fileStorageEntryVersionListStorage,
    deleteFileStorageEntryVersionEffect,
    restoreFileStorageEntryVersionEffect,
    changeCommentsCountEvent,
  } = useMemo(getFileStorageEntryVersionListStorage, []);

  const {
    data: fileStorageEntryVersionListData,
    loading: isFileStorageEntryVersionListLoading,
    error: fileStorageEntryVersionListError,
    pagination: { count },
  } = useAbstractStorage(fileStorageEntryVersionListStorage, {
    autoFetchAndRefetch: Boolean(id),
    autoFetchParams: { id, ...pageParams },
    cancelPendingRequestOnUnmount: true,
  });

  const isAllPagesShowed = fileStorageEntryVersionListData.length === count;

  const onDownloadMore = () => {
    const { current } = pagination;

    if (current && !isAllPagesShowed) {
      pagination.onChange(current + 1, 4);
    }
  };

  const expandIcon: UiCollapseExpandIcon = ({ isActive }) => (
    <UiIcon
      color={variables.themePalette.colorIcon}
      component={isActive ? RemoveSquareOutlineSvg : AddSquareOutlineSvg}
      width={20}
      height={20}
    />
  );

  const onDeleteRevision = useCallback(
    (revision: FileStorageEntryRevision) => {
      deleteFileStorageEntryVersionEffect({ id, revision })
        .then(fileInfoStorage.storage.refetchWithLastParams)
        .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось удалить версию')));
    },
    [id],
  );

  const onRestoreRevision = useCallback(
    (revision: FileStorageEntryRevision) => {
      restoreFileStorageEntryVersionEffect({ id, revision })
        .then(fileInfoStorage.storage.refetchWithLastParams)
        .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось восстановить версию')));
    },
    [id],
  );

  const initialFileStorageEntryVersionListRequest = () => {
    fileStorageEntryVersionListStorage.resetStoreEvent();
    onResetPage();
    fileStorageEntryVersionListStorage.fetchEffect({ id, pageSize: 4, pageNumber: 1 });
  };

  const initialFileStorageEntryHistoryRequest = () => {
    fileStorageEntryHistoryStorage.storage.resetStoreEvent();
    fileStorageEntryHistoryStorage.storage.fetchEffect({ id, pageSize: 5, pageNumber: 1 });
  };

  const onCommentSuccess: CommentListStandaloneProps['onComment'] = (objectId) =>
    changeCommentsCountEvent({ objectId, type: ChangeCommentsCountType.Increase });
  const latestVersionId = fileInfoData?.versionId || 0;
  const latestRevision = useMemo(
    () => fileStorageEntryVersionListData.find((item) => item.versionId === latestVersionId)?.revision,
    [fileStorageEntryVersionListData, latestVersionId],
  );
  const isOnlyRevision = useMemo<boolean>(
    () => fileStorageEntryVersionListData.filter(({ isDeleted }) => !isDeleted).length === 1,
    [fileStorageEntryVersionListData],
  );
  const commentsStorages = useMemo<Record<FileStorageEntryVersionId, CommentListStorage>>(() => {
    return fileStorageEntryVersionListData.reduce(
      (acc, { versionId }) => ({
        ...acc,
        [versionId]: getCommentListStorage(),
      }),
      {},
    );
  }, [fileStorageEntryVersionListData]);

  const items = useMemo<UiCollapseProps['items']>(() => {
    return fileStorageEntryVersionListData.map((version) => {
      const { versionId } = version;

      return {
        key: versionId,
        label: (
          <FileVersionsPanelHeader {...version}>
            <FileVersionsPanelExtra
              {...version}
              isOnlyRevision={isOnlyRevision}
              isLatestRevision={versionId === latestVersionId}
              onDelete={onDeleteRevision}
              onRestore={onRestoreRevision}
            />
          </FileVersionsPanelHeader>
        ),
        children: (
          <CommentListStandalone
            objectId={versionId}
            onComment={onCommentSuccess}
            storage={commentsStorages[versionId]}
            contentType={CommentContentTypes.FileVersion}
          />
        ),
      };
    });
  }, [fileStorageEntryVersionListData]);

  const commentInputPlaceholder = `Оставьте комментарий к Версии ${latestRevision}`;

  const onUploadAttachment = useCallback<(option: UiUploadRequestOption) => void>(
    ({ file }) => {
      const uploadedFile = file as File;

      checkFileSizeUploadEffect({
        file: uploadedFile,
        maxSize: 2,
        unitSize: FileSizeUnit.GB,
      })
        .then((fileUpload) =>
          uploadAttachmentEffect({ apiVersion: FileStorageApiVersions.v3, ...fileUpload }),
        )
        .then(({ versionId }) => createUpdateFileStorageEntryEffect({ id, versionId }))
        .then(({ revision }) => {
          message.success(`Версия ${revision} успешно загружена`);
          fileInfoStorage.storage.refetchWithLastParams();
          initialFileStorageEntryVersionListRequest();
          initialFileStorageEntryHistoryRequest();
        })
        .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка создания версии файла')));
    },
    [id, fileInfoStorage, fileStorageEntryVersionListStorage],
  );

  const onComment = useCallback(
    (text: string, parentId: CommentId | null = null) => {
      const { createCommentEffect } = commentsStorages[latestVersionId];
      const params = { objectId: latestVersionId, contentType: CommentContentTypes.FileVersion };

      createCommentEffect({ ...params, text, parentId }).then(() => onCommentSuccess(latestVersionId));
    },
    [latestVersionId, commentsStorages],
  );

  const isShowData = !isFileStorageEntryVersionListLoading && fileStorageEntryVersionListData.length > 0;
  const isShowError = !isFileStorageEntryVersionListLoading && fileStorageEntryVersionListError;

  return (
    <UiCard emptyPadding={!isFileStorageEntryVersionListLoading} size="default" emptyRadius={!isLayoutLarge}>
      {isFileStorageEntryVersionListLoading && (
        <UiSkeleton loading count={3} height={50} block style={{ marginBottom: spaceM }} />
      )}
      {isShowError && <UiEmpty.Feed emptyMessage={{ header: 'Ошибка загрузки версий' }} />}
      {isShowData && (
        <>
          <UiCard.Header>
            <UploadDraggerArea
              onUploadAttachment={onUploadAttachment}
              loading={isCreateFilePending}
              multiple={false}
              description="чтобы загрузить версию"
              accept={FileUploadAccepts.All}
              compact
            />
          </UiCard.Header>
          <UiCard.Content basePadding style={{ paddingTop: 0 }}>
            <UiSpace size="small" full direction="vertical">
              <UiCollapse
                items={items}
                ghost
                showLine
                defaultActiveKey={latestVersionId}
                expandIcon={expandIcon}
              />
              {!isAllPagesShowed && (
                <UiButton type="link" onClick={onDownloadMore} label="Показать более ранние версии" />
              )}
            </UiSpace>
          </UiCard.Content>
          <UiCard.Footer hasTopBorder>
            <CommentInput placeholderText={commentInputPlaceholder} onSend={onComment} />
          </UiCard.Footer>
        </>
      )}
    </UiCard>
  );
};
