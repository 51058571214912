import { Notification } from '@vkph/components';
import { UiAvatarProps, UiButton, UiSpace, UiTypography, useSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { ExternalContentModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

interface Props {
  notification: NotificationModel<ExternalContentModel>;
}

export const NotificationExternal: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    content,
  } = notification;
  const { title, description, buttonLink, buttonLabel } = content;
  const { spaceS } = useSpace();
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={getFullNameWithoutPatronymic(fullName)}
        subtitle={description}
        avatarProps={avatar}
        isActive={isActive}
      />

      <Notification.Body>
        <UiSpace size={spaceS} direction="vertical" full>
          <UiTypography.Text strong>{title}</UiTypography.Text>
          {buttonLink && (
            <UiButton
              style={{ width: 'fit-content' }}
              type="primary"
              href={buttonLink}
              label={buttonLabel || 'Перейти'}
            />
          )}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
