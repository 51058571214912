import { UiCard, UiDivider } from '@vkph/ui';
import React, { FC } from 'react';

import { SkillType } from '@vkph/common/types/models';

import { Skills, SkillsProps } from './Skills';
import styles from './SkillsGroup.scss';

type SkillsGroupProps = Omit<SkillsProps, 'skillType'>;

export const SkillsGroup: FC<SkillsGroupProps> = (props) => (
  <UiCard size="default" emptyPadding className={styles.skillsGroup}>
    <Skills {...props} skillType={SkillType.Skill} />
    <UiDivider emptyMargin />
    <Skills {...props} skillType={SkillType.Competence} />
  </UiCard>
);
