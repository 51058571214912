import { UiFlex } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

export const MenuLinkItem: FC<PropsWithChildren<LinkProps>> = (props) => {
  const { to, children, ...restProps } = props;
  const location = useLocation();

  if (location.pathname === to) {
    return <UiFlex {...restProps}>{children}</UiFlex>;
  }

  return (
    <Link to={to} {...restProps}>
      {children}
    </Link>
  );
};
