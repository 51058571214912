import { EmployeeContacts, UserProfile } from '@vkph/components';
import { UiCard, UiDivider, UiIcon, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { getProfileStructureInfoStorage } from '@vkph/common/store/profile';
import { getFullNameWithPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';
import UserSvg from '@vkph/ui/svg/user.svg';

export const UserProfileWidget: FC = () => {
  const currentProfile = useCurrentProfile();
  const [{ variables: themeVariables }] = useTheme();
  const { spaceXS, spaceL } = useSpace();

  const profileStructureInfoStorage = useMemo(getProfileStructureInfoStorage, []);

  const { data: profileStructureInfoData } = useAbstractStorage(profileStructureInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id: currentProfile.id },
    cancelPendingRequestOnUnmount: true,
  });

  const UserGroups: FC = () => {
    const { divisionId, division, organization, organizationId } = profileStructureInfoData || {};

    return (
      <UiSpace size={4} direction="vertical">
        {organization && organizationId && (
          <Link to={getRoutePath(RouteNames.Structure, { id: organizationId })} key={organizationId}>
            <UiTypography.Footnote type="secondary">
              <UiTruncateMarkup truncate>{organization}</UiTruncateMarkup>
            </UiTypography.Footnote>
          </Link>
        )}
        {divisionId && division && (
          <Link to={getRoutePath(RouteNames.Structure, { id: divisionId })} key={divisionId}>
            <UiTypography.Footnote type="secondary">
              <UiTruncateMarkup truncate>{division}</UiTruncateMarkup>
            </UiTypography.Footnote>
          </Link>
        )}
      </UiSpace>
    );
  };

  return (
    <UiCard size="default" emptyPadding>
      <UiCard.Header>
        <UiSpace size={8}>
          <UiIcon component={UserSvg} width={24} height={24} color={themeVariables.colorBrand} />
          <UiCard.Header.Title>Мой профиль</UiCard.Header.Title>
        </UiSpace>
      </UiCard.Header>
      <UiDivider type="horizontal" orientation="left" column emptyMargin>
        <UserProfile.Info
          size="large"
          style={{ padding: spaceL }}
          fullNameProps={{ level: 4 }}
          user={{ ...currentProfile, ...currentProfile.fullName }}
          getFullName={getFullNameWithPatronymic}
        >
          <UiSpace size={spaceXS} direction="vertical">
            {currentProfile.position && (
              <UiTypography.Text type="secondary">
                <UiTruncateMarkup truncate>{currentProfile.position.name}</UiTruncateMarkup>
              </UiTypography.Text>
            )}
            <UserGroups />
          </UiSpace>
        </UserProfile.Info>
      </UiDivider>
      <EmployeeContacts userId={currentProfile.id} />
    </UiCard>
  );
};
