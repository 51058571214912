import {
  UiDivider,
  UiSpace,
  useBreakpoint,
  UiCard,
  UiMenu,
  UiMenuProps,
  UiSkeleton,
  UiTabs,
  UiTabsProps,
} from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useEffect, useMemo } from 'react';

import { useQueryParams } from '@vkph/common/hooks';
import { NewsCategoriesDictStorage, NewsFeedListParams } from '@vkph/common/store/news';
import { HistoryActionType } from '@vkph/common/types';
import { createArrayMock } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

export enum NewsCategoriesKey {
  All = 'all',
}

export type NewsCategoriesParams = Pick<NewsFeedListParams, 'categoryIds'>;

type Props = {
  loading: boolean;
  newsCategoriesStorage: NewsCategoriesDictStorage;
};

const MOCKS_LENGTH = 6;
const newsCategoriesMocks = createArrayMock(MOCKS_LENGTH, (_, key) => (
  <UiSkeleton key={key} loading height={18} width={155} />
));

export const NewsCategories: FC<Props> = (props) => {
  const { loading, newsCategoriesStorage } = props;
  const breakpoints = useBreakpoint();
  const { spaceL, spaceXL: spaceXLpx } = useSpace({ unit: 'px' });
  const { spaceM, spaceXL } = useSpace();
  const { queryParams, setQueryParams } = useQueryParams<NewsCategoriesParams>();
  const { categoryIds } = queryParams;

  const categories = useStoreMap({
    store: newsCategoriesStorage.storage.store,
    keys: ['data'],
    fn: ({ data }) => (data ? Object.values(data) : data),
  });

  useEffect(() => {
    const isCategoriesHasCategoryId = categories?.find(({ id }) => id === Number(categoryIds));
    const isSetAllNewsCategory = !categoryIds || (!loading && !isCategoriesHasCategoryId);

    if (isSetAllNewsCategory && categoryIds !== NewsCategoriesKey.All) {
      setQueryParams({ categoryIds: NewsCategoriesKey.All }, HistoryActionType.Replace);
    }
  }, [loading, categoryIds, categories]);

  const onSelect: UiMenuProps['onSelect'] = ({ key }) => setQueryParams({ categoryIds: key });
  const onChange: UiTabsProps['onChange'] = (key) => setQueryParams({ categoryIds: key });

  const menuItems = useMemo(() => {
    const categoriesItems = (categories || []).map((category) => ({
      key: category.id.toString(),
      label: category.name,
    }));

    return [{ key: NewsCategoriesKey.All, label: 'Все новости' }, ...categoriesItems];
  }, [categories]);

  if (breakpoints.lg) {
    return (
      <UiCard size="default" title="Категории" styles={{ body: { padding: `0 0 ${spaceXLpx}` } }}>
        <UiSkeleton
          loading={loading}
          vertical
          count={6}
          width={200}
          height={20}
          style={{ margin: `${spaceL} ${spaceXLpx}` }}
        />

        {Boolean(categories?.length) && categoryIds && (
          <UiMenu selectedKeys={[categoryIds]} onSelect={onSelect} items={menuItems} />
        )}
      </UiCard>
    );
  }

  return (
    <>
      <UiCard size="default" emptyPadding emptyRadius>
        {loading && (
          <UiSpace size={spaceXL} full style={{ padding: spaceM }} align="center">
            {newsCategoriesMocks}
          </UiSpace>
        )}
        {Boolean(categories?.length) && categoryIds && !loading && (
          <UiTabs
            defaultActiveKey={categoryIds}
            activeKey={categoryIds}
            items={menuItems}
            onChange={onChange}
            size="small"
          />
        )}
      </UiCard>
      <UiDivider emptyMargin />
    </>
  );
};
