import { Layout, UiBreadcrumb, UiBreadcrumbItemType } from '@vkph/ui';
import React, { useState, useMemo, FC } from 'react';

import { Dictionaries } from '@vkph/common/types/models';
import { RouteNames, getRoutePath } from '@vkph/common/utils';

import { SearchCategory } from '../../store/search';
import { SearchContent } from './search-content/SearchContent';
import { SearchFilters } from './search-filters/SearchFilters';

export interface SearchFilter {
  label: string;
  id?: string;
  key?: string;
  value?: string;
}

export interface FilterCatogories extends SearchFilter {
  searchTitle: string;
  searchPlaceholder: string;
  dictionaryName: Dictionaries.Names;
}

type Props = {
  minSymbolsToSearch: number;
  searchFilters: FilterCatogories[];
  breadcrumbName: string;
};

export const SearchPage: FC<Props> = (props) => {
  const { minSymbolsToSearch, searchFilters, breadcrumbName } = props;
  const [selectedSearchFilters, setSelectedSearchFilters] = useState<SearchFilter[]>([]);

  const changeFilters = (selectedSearchFilter: SearchFilter) => {
    const notChangedFilters = selectedSearchFilters.filter(
      (searchFilter) => searchFilter.label !== selectedSearchFilter.label,
    );

    if (selectedSearchFilter.id) {
      setSelectedSearchFilters([...notChangedFilters, selectedSearchFilter]);
    } else {
      setSelectedSearchFilters(notChangedFilters);
    }
  };

  const deleteFilters = () => setSelectedSearchFilters([]);

  const breadcrumbRoutes = useMemo<UiBreadcrumbItemType[]>(
    () => [
      {
        path: getRoutePath(RouteNames.Home),
        title: 'Главная',
      },
      {
        path: getRoutePath(RouteNames.Search),
        title: breadcrumbName,
      },
    ],
    [breadcrumbName],
  );

  const fetchParams = useMemo(
    () =>
      selectedSearchFilters.reduce(
        (params, filter) => {
          const filterParam = filter.key ? { [filter.key]: filter.value } : {};

          return { ...params, ...{ filterParam } };
        },
        {
          search: '',
        },
      ),
    [selectedSearchFilters],
  );

  return (
    <>
      <Layout.Breadcrumb>
        <UiBreadcrumb items={breadcrumbRoutes} />
      </Layout.Breadcrumb>
      <Layout.Main>
        <Layout.Content stretch>
          <SearchContent
            selectedSearchFilters={selectedSearchFilters}
            fetchParams={fetchParams}
            deleteFilter={changeFilters}
            deleteAllFilters={deleteFilters}
            origin={SearchCategory.Profile}
            minSymbolsToSearch={minSymbolsToSearch}
          />
        </Layout.Content>
        {searchFilters.length > 0 && (
          <Layout.Sider>
            <SearchFilters
              changeFilters={changeFilters}
              searchFilters={searchFilters}
              selectedSearchFilters={selectedSearchFilters}
            />
          </Layout.Sider>
        )}
      </Layout.Main>
    </>
  );
};
