import { UiFlex, UiIcon, UiTypography, useSpace } from '@vkph/ui';
import React, { FC, MouseEvent, PropsWithChildren, ReactNode, SVGProps } from 'react';

import { SiteNavbarItemModel, SiteServiceType } from '@vkph/common/types/models';
import CalendarSVG from '@vkph/ui/svg/calendar.svg';
import DraftSvg from '@vkph/ui/svg/draft.svg';
import FolderOpenSvg from '@vkph/ui/svg/folder-open.svg';
import LinkSvg from '@vkph/ui/svg/link.svg';
import StructureSvg from '@vkph/ui/svg/structure.svg';
import TableSvg from '@vkph/ui/svg/table.svg';

import styles from './SiteNavbarMenuLabel.scss';

const iconsMap: Partial<Record<SiteServiceType, FC<SVGProps<SVGSVGElement>>>> = {
  [SiteServiceType.Calendar]: CalendarSVG,
  [SiteServiceType.ExternalLink]: LinkSvg,
  [SiteServiceType.FileStorage]: FolderOpenSvg,
  [SiteServiceType.List]: TableSvg,
  [SiteServiceType.News]: DraftSvg,
};

type Props = {
  item: SiteNavbarItemModel;
  extra?: ReactNode;
};

export const SiteNavbarMenuLabel: FC<PropsWithChildren<Props>> = (props) => {
  const { item, extra, children } = props;
  const { additional, type } = item;
  const { spaceXS, spaceM } = useSpace();
  const icon = additional?.isMain ? StructureSvg : iconsMap[type];

  const onExtraClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <UiFlex className={styles.siteNavbarMenuLabel} flex="auto" gap={spaceXS}>
      {icon && <UiIcon width={20} height={20} component={icon} />}
      <UiFlex flex="auto">
        <UiTypography.Text>{children}</UiTypography.Text>
      </UiFlex>
      <UiFlex
        className={styles.siteNavbarMenuLabel__extra}
        align="center"
        gap={spaceM}
        onClick={onExtraClick}
      >
        {extra}
      </UiFlex>
    </UiFlex>
  );
};
