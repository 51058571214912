import { Markdown, UserAvatar } from '@vkph/components';
import { UiCol, UiRow, UiSpace, UiTypography, useBreakpoint } from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { CommentContentTypes, CommentDetailListModel } from '@vkph/common/types/models';
import {
  getFullNameWithoutPatronymic,
  getRoutePath,
  RouteNames,
  getFormattedDateToWord,
  parseEntityText,
} from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { CommentListItemLink } from './link/CommentListItemLink';

const commentLinkPrefixes = {
  [CommentContentTypes.Post]: 'к',
  [CommentContentTypes.Micropost]: 'к',
  [CommentContentTypes.NewsEntry]: 'к новости',
  [CommentContentTypes.Gamification]: 'к награде',
  [CommentContentTypes.ProfileEntry]: 'к профилю',
  [CommentContentTypes.AlbumImage]: 'к изображению в группе',
  [CommentContentTypes.File]: 'к файлу',
  [CommentContentTypes.FileVersion]: 'к файлу',
  [CommentContentTypes.Cms]: 'к странице',
  [CommentContentTypes.Project]: 'к проекту',
  [CommentContentTypes.Task]: 'к задаче',
  [CommentContentTypes.Thanks]: 'к благодарности',
  [CommentContentTypes.TimelineRecord]: 'к записи',
  [CommentContentTypes.UserBadge]: 'к награде',
  [CommentContentTypes.Idea]: 'к идее',
  [CommentContentTypes.SitePage]: 'к странице сайта',
};

type Props = {
  comment: CommentDetailListModel;
  onClickProfileLink?: () => void;
};

export const CommentListItem: FC<Props> = (props) => {
  const { comment, onClickProfileLink } = props;
  const { user, text, createdAt, contentType, data } = comment;
  const { textValue: commentText } = useMemo(() => parseEntityText(text), [text]);
  const { lg: isLayoutLarge } = useBreakpoint();
  const { space2XS, spaceS, spaceXL } = useSpace();
  const commentItemPadding = usePaddingStyle([spaceS, spaceXL]);

  const linkPrefix = commentLinkPrefixes[contentType];
  const profileId = user.keycloakUser?.keycloakId || '';
  const profileRoute = getRoutePath(RouteNames.Profile, { id: profileId });
  const fullName = getFullNameWithoutPatronymic(user);
  const createdAtFormatted = getFormattedDateToWord({ date: createdAt });
  const isUserActive = Boolean(comment.user.isActive || comment.user.keycloakUser?.isActive);
  const commentTextMarkup = (
    <UiTypography.Paragraph ellipsis={{ rows: 4 }} style={{ marginBottom: 0 }}>
      <Markdown>{commentText}</Markdown>
    </UiTypography.Paragraph>
  );

  return (
    <UiSpace style={commentItemPadding} direction="vertical" full>
      <UiSpace size={spaceS} full align="start">
        <Link to={profileRoute} onClick={onClickProfileLink}>
          <UserAvatar size={40} isActive={isUserActive} src={user.avatar} />
        </Link>
        <UiSpace direction="vertical" size={space2XS} full>
          <UiRow gutter={[space2XS, 0]}>
            <UiCol>
              <Link to={profileRoute} onClick={onClickProfileLink}>
                <UiTypography.Text strong>{fullName}</UiTypography.Text>
              </Link>
            </UiCol>
            <UiCol>
              <UiTypography.Text type="secondary">оставил комментарий {linkPrefix}</UiTypography.Text>
            </UiCol>
            {data && (
              <UiCol>
                <CommentListItemLink comment={comment} />
              </UiCol>
            )}
          </UiRow>

          <UiSpace full direction="vertical">
            <UiTypography.Text type="secondary">{createdAtFormatted}</UiTypography.Text>
            {isLayoutLarge && commentTextMarkup}
          </UiSpace>
        </UiSpace>
      </UiSpace>

      {!isLayoutLarge && commentTextMarkup}
    </UiSpace>
  );
};
