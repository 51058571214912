import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { SearchParams, SitePageModel } from '@vkph/common/types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '@vkph/common/utils';

export const getSearchSitesPagesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<SitePageModel>,
    SitePageModel[],
    SitePageModel[],
    SearchParams
  >({
    endpointBuilder: ({ siteId: id = '', ...params }) =>
      buildEndpointWithQueryParams(endpoints.sitector.sitesEntryIdPages(id), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};
