import { UiButton, UiCol, UiEmpty, UiRow, useBreakpoint, usePaddingStyle, useSpace } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, ReactNode } from 'react';

import { NewsFeedStorage } from '@vkph/common/store/news';
import { NewsArticle } from '@vkph/common/types/models';
import { createArrayMock } from '@vkph/common/utils';

import { NewsPost } from '../post';

type Props = {
  newsFeedStorage: NewsFeedStorage;
  hasNext: boolean;
  onLoadNext: () => void;
  renderItem: (item: NewsArticle) => ReactNode;
};

export const NewsFeed: FC<Props> = (props) => {
  const { renderItem, newsFeedStorage, hasNext, onLoadNext } = props;

  const { spaceXS, spaceM, spaceL } = useSpace();
  const { lg: isLayoutLarge } = useBreakpoint();
  const loadMorePadding = usePaddingStyle(isLayoutLarge ? [spaceM, 0, 0] : [spaceL, spaceM]);
  const { data: newsFeedData } = useStore(newsFeedStorage.storage.store);
  const isFeedLoading = useStore(newsFeedStorage.storage.fetchEffect.pending);
  const isLoading = isFeedLoading && !newsFeedData.length;
  const isEmpty = !isLoading && !newsFeedData.length;

  return (
    <>
      {isEmpty && <UiEmpty.Feed bordered />}
      <UiRow gutter={[0, { xs: spaceXS, sm: spaceXS, lg: spaceM }]}>
        {isLoading &&
          createArrayMock(3, (_, index) => (
            <UiCol key={index} span={24}>
              <NewsPost.Skeleton />
            </UiCol>
          ))}
        {!isLoading &&
          newsFeedData.map((item) => (
            <UiCol key={item.id} span={24}>
              {renderItem(item)}
            </UiCol>
          ))}
      </UiRow>
      {hasNext && (
        <UiRow style={loadMorePadding}>
          <UiButton
            block
            type="primary"
            size="large"
            label="Загрузить ещё"
            loading={isFeedLoading}
            disabled={isFeedLoading}
            onClick={onLoadNext}
          />
        </UiRow>
      )}
    </>
  );
};
