import { UiModalProps, UiSpinner, useBreakpoint } from '@vkph/ui';
import React, { FC, Suspense } from 'react';

import { FileStorageListEntryModel } from '@vkph/common/types/models';

const FileListManagerNavigationDesktopModal = React.lazy(() => import('./desktop'));
const FileListManagerNavigationMobileDrawer = React.lazy(() => import('./mobile'));

interface Props extends Pick<UiModalProps, 'onClose'> {
  folder: FileStorageListEntryModel;
}

export const FileListManagerExtensionsModal: FC<Props> = (props) => {
  const { lg: isLayoutLarge } = useBreakpoint();

  if (isLayoutLarge) {
    return (
      <Suspense fallback={<UiSpinner spinning />}>
        <FileListManagerNavigationDesktopModal {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<UiSpinner spinning />}>
      <FileListManagerNavigationMobileDrawer {...props} />
    </Suspense>
  );
};
