import { Editor, Post, Reactions, ShareButton } from '@vkph/components';
import {
  UiDivider,
  UiEmpty,
  UiImage,
  UiRender,
  UiRenderType,
  UiSpace,
  UiTag,
  UiTypography,
  message,
  useBreakpoint,
} from '@vkph/ui';
import React, { CSSProperties, FC, useCallback, useEffect, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getNewsCategoriesDictStorage } from '@vkph/common/store/news';
import { reactionEffect } from '@vkph/common/store/reactions';
import { LongreadBaseProps, NewsLongreadParams } from '@vkph/common/types/longread';
import { EmojiUuid, PostNewsModel, ReactionContentType } from '@vkph/common/types/models';
import { getActualEditorFormat, getErrorResponseMessage } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';

import { deletePostEffect, getSinglePostStorage } from '../../../store/post';
import { LongreadComments } from '../comments/LongreadComments';
import { LongreadLoading } from '../loading/LongreadLoading';

type Props = LongreadBaseProps & NewsLongreadParams;

export const NewsLongread: FC<Props> = (props) => {
  const { id, newsType, onClose } = props;

  const [{ variables: themeVariables }] = useTheme();
  const newsStorage = useMemo(() => getSinglePostStorage<PostNewsModel>(), []);
  const newsCategoriesStorage = useMemo(getNewsCategoriesDictStorage, []);
  const { spaceL } = useSpace();
  const { spaceM, space2XL, space4XL } = useSpace({ unit: 'px' });

  const { lg: isLayoutLarge } = useBreakpoint();

  const { updateCommentsCountEvent } = newsStorage;
  const {
    data: news,
    loading: isNewsLoading,
    error,
  } = useAbstractStorage(newsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { postId: id, postType: newsType },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const { data: newsCategories } = useAbstractStorage(newsCategoriesStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  useEffect(() => {
    if (error) {
      onClose();
      message.error(getErrorResponseMessage(error, `Ошибка. Новости с идентификатором ${id} не существует`));
    }
  }, [error]);

  const baseSectionStyle: CSSProperties = { padding: isLayoutLarge ? `${space2XL} ${space4XL}` : spaceM };

  const isReactedDisabled = !news?.settings?.isReacted;
  const isCommentsDisabled = !news?.settings?.isComments;

  const reactions = !isReactedDisabled ? news?.reactions : undefined;
  const comments = !isCommentsDisabled ? news?.commentsCount : undefined;
  const views = news?.viewsCount || 0;
  const titleStyle = {
    fontSize: isLayoutLarge ? 40 : 20,
    lineHeight: isLayoutLarge ? '44px' : '24px',
    marginBottom: 0,
  };

  const onDelete = useCallback(() => {
    deletePostEffect({ postId: id, postType: newsType }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось удалить новость')),
    );
  }, [id, newsType, deletePostEffect]);

  const onReaction = useCallback(
    (emojiUuid?: EmojiUuid) => {
      reactionEffect({
        emojiUuid,
        objectId: id,
        contentType: ReactionContentType.News,
      }).catch((e) =>
        message.error(
          getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`),
        ),
      );
    },
    [id],
  );

  const tagComponent = useMemo(() => {
    if (news && newsCategories?.[news?.categoryId]) {
      return (
        <UiTag
          title={newsCategories[news.categoryId].name}
          color={newsCategories[news.categoryId].extraInfo?.color}
        />
      );
    }

    return null;
  }, [news]);

  const isError = (!news && !isNewsLoading) || error;

  return (
    <>
      {isNewsLoading && <LongreadLoading />}
      {isError && <UiEmpty.Feed emptyMessage={{ header: 'Новость не загрузилась' }} />}
      {news && (
        <>
          <UiSpace size={spaceL} direction="vertical" full style={baseSectionStyle}>
            <Post.Header author={news.author} postDate={news.publishedAt || news.createdAt}>
              <UiSpace>
                {isLayoutLarge && tagComponent}
                <Post.Header.Actions post={news} onDelete={onDelete} />
              </UiSpace>
            </Post.Header>
            {!isLayoutLarge && tagComponent}
            <UiTypography.Title style={titleStyle}>{news.title}</UiTypography.Title>
            <UiImage src={news.cover} />
            <Editor.Viewer html={getActualEditorFormat(news.body).data} />
            {news.tags.length > 0 && (
              <UiTag.Group tagProps={{ color: themeVariables.colorBrand16 }} tags={news.tags} />
            )}
          </UiSpace>

          <Reactions
            style={baseSectionStyle}
            onReaction={onReaction}
            reactions={reactions}
            comments={comments}
            views={views}
            stretch
          >
            <ShareButton label="Поделиться" />
          </Reactions>

          <UiRender type={UiRenderType.NullElement} visible={!isCommentsDisabled && isLayoutLarge}>
            <UiDivider emptyMargin />
            <LongreadComments
              style={{ paddingLeft: space4XL, paddingRight: space4XL }}
              post={news}
              onCommentsCountUpdate={updateCommentsCountEvent}
              showInput
            />
          </UiRender>
        </>
      )}
    </>
  );
};
