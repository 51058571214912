// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dd6c5088_site-navbar-menu-label__extra{transition:opacity .3s;opacity:0}.ant-tree-treenode:hover:not(.dragging) .dd6c5088_site-navbar-menu-label__extra{opacity:1}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/site-navbar-menu/label/SiteNavbarMenuLabel.scss"],"names":[],"mappings":"AAEE,wCACE,sBAAA,CACA,SAAA,CAGF,gFACE,SAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.site-navbar-menu-label {\n  &__extra {\n    transition: opacity 0.3s;\n    opacity: 0;\n  }\n\n  :global(.ant-tree-treenode:hover:not(.dragging)) &__extra {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"site-navbar-menu-label__extra": "dd6c5088_site-navbar-menu-label__extra",
	"siteNavbarMenuLabel__extra": "dd6c5088_site-navbar-menu-label__extra"
};
export default ___CSS_LOADER_EXPORT___;
