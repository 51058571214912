import { UiFlex, UiIcon, UiRow, UiTruncateMarkup, UiTypography, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { FileStorageEntryVersionModel } from '@vkph/common/types/models';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import CommentOutlineSvg from '@vkph/ui/svg/comment-outline.svg';

export const FileVersionsPanelHeader: FC<PropsWithChildren<FileStorageEntryVersionModel>> = (props) => {
  const { revision, name, commentsCount, isDeleted, children } = props;

  const deletedVersion = isDeleted ? `Удалено: ${name}` : name;
  const heading = `Версия ${revision} — ${deletedVersion}`;

  const { spaceXS } = useSpace();
  const padding = usePaddingStyle([spaceXS, 0]);

  return (
    <UiFlex
      flex="1 0 200px"
      gap={spaceXS}
      align="center"
      justify="space-between"
      wrap={false}
      style={padding}
    >
      <UiRow>
        <UiTypography.Text
          strong
          type={isDeleted ? UiTypographyTextTypes.Danger : UiTypographyTextTypes.Primary}
        >
          <UiTruncateMarkup truncate>{heading}</UiTruncateMarkup>
        </UiTypography.Text>
      </UiRow>
      {commentsCount > 0 && (
        <UiIcon.Label
          label={commentsCount.toString()}
          type={UiTypographyTextTypes.Primary}
          strong
          component={CommentOutlineSvg}
          color={variables.themePalette.colorIcon}
        />
      )}
      {children}
    </UiFlex>
  );
};
