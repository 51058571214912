import { UiIcon, UiButton, UiTooltip, useTheme, useSpace } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import BurgerIcon from '@vkph/ui/svg/burger.svg';

import styles from './HeaderNavbarButton.scss';

export const HeaderNavbarButton: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { spaceXS } = useSpace();
  const [{ variables: themeVariables }] = useTheme();

  return (
    <UiTooltip
      color={themeVariables.colorBgPrimary}
      destroyTooltipOnHide={false}
      forceRender
      arrow={false}
      title={children}
      overlayStyle={{ paddingLeft: spaceXS }}
      rootClassName={styles.headerNavbarButton}
    >
      <UiButton type="icon-inverted" icon={<UiIcon component={BurgerIcon} width={20} height={20} />} />
    </UiTooltip>
  );
};
