import {
  UiBreadcrumbProps,
  UiMentionClearInputSearchHandler,
  UiMentionsInputValue,
  UiSpinner,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, Suspense } from 'react';

import { FileStorageEntryId } from '@vkph/common/types/models';

const FileListManagerNavigationDesktop = React.lazy(() => import('./desktop'));
const FileListManagerNavigationMobile = React.lazy(() => import('./mobile'));

export type FileListManagerNavigationProps = {
  fileStorageRootId: FileStorageEntryId;
  routes: UiBreadcrumbProps['items'];
  onSearch: (value: UiMentionsInputValue) => void;
  onClear?: UiMentionClearInputSearchHandler;
  onCreateFolder?: () => void;
  isManageFileStorage?: boolean;
};

export const FileListManagerNavigation: FC<FileListManagerNavigationProps> = (props) => {
  const { lg: isLayoutLarge } = useBreakpoint();

  if (isLayoutLarge) {
    return (
      <Suspense fallback={<UiSpinner spinning />}>
        <FileListManagerNavigationDesktop {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<UiSpinner spinning />}>
      <FileListManagerNavigationMobile {...props} />
    </Suspense>
  );
};
