import { UiModal, UiModalTypes } from '@vkph/ui';
import React, { FC } from 'react';

import {
  FileListManagerExtensionBase,
  FileListManagerExtensionsBaseProps,
} from '../FileListManagerExtensionBase';

export const FileListManagerExtensionsModalDesktopModal: FC<FileListManagerExtensionsBaseProps> = (props) => {
  const { onClose, folder } = props;

  return (
    <UiModal type={UiModalTypes.Medium} isOpen={Boolean(folder)} onClose={onClose}>
      <FileListManagerExtensionBase {...props} />
    </UiModal>
  );
};
