import { NavigationMenuGroupItem } from '@vkph/common/types/models';
import { hashMapMaker } from '@vkph/common/utils';

import { FavoriteItem } from './NavigationMenu';

export const getCheckedItems = (
  selectedFavoriteItems: FavoriteItem[],
  menuItems: NavigationMenuGroupItem[],
) => {
  const favoritesList = hashMapMaker(selectedFavoriteItems, 'id');

  const menu: Record<string, NavigationMenuGroupItem> = {};

  menuItems?.forEach((item) => {
    const checked = Boolean(favoritesList[item.id]?.checked);
    const value = { ...item, checked };

    menu[item.id] = value;
  });
  return { menu };
};
