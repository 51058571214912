import { UiButton, UiForm, UiInput, message, UiModal } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import {
  createFileStorageCategoryEffect,
  updateFileStorageCategoryEffect,
} from '@vkph/common/store/filestorage';
import { GlobalModalNames, GlobalModalsStorePayloads } from '@vkph/common/store/global-modals';
import {
  FileStorageCategoryId,
  FileStorageCategoryModel,
  FileStorageEntryId,
} from '@vkph/common/types/models';
import { MAX_LENGTH_INPUT_50, requiredRule, getErrorResponseMessage } from '@vkph/common/utils';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type Props = NonNullable<
  GlobalModalsStorePayloads[GlobalModalNames.FileStorageCategoryCreateUpdate]['payload']
>;

type FormValues = {
  id?: FileStorageCategoryId;
  name: string;
  rootFolder: FileStorageEntryId;
};

export const FileStorageCategoryCreateUpdateModal: FC<Props> = (props) => {
  const { category, onClose, entryId, onSuccess } = props;
  const [form] = UiForm.useForm<FormValues>();
  const isEdit = Boolean(category);

  const initialFormValues = useMemo<Partial<FormValues>>(() => {
    return category
      ? {
          id: category.id,
          name: category.name,
          rootFolder: category.rootFolder,
        }
      : {
          name: '',
        };
  }, [category]);

  const onFinish = () => {
    const { rootFolder, ...restFormValues } = form.getFieldsValue(true);
    const params = {
      ...restFormValues,
      rootFolder: rootFolder || entryId,
    } satisfies Partial<FileStorageCategoryModel>;

    const finishEffect = isEdit ? updateFileStorageCategoryEffect : createFileStorageCategoryEffect;

    finishEffect(params)
      .then((data) => {
        message.success(`Категория успешно ${isEdit ? 'обновлена' : 'создана'}`);
        onSuccess?.(data);
        onClose();
      })
      .catch((e) =>
        message.error(getErrorResponseMessage(e, `Ошибка ${isEdit ? 'обновления' : 'создания'} категории`)),
      );
  };

  return (
    <UiForm size="large" onFinish={onFinish} form={form} initialValues={initialFormValues} layout="vertical">
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          steps={getModalStepsForSingleTitle(`${isEdit ? 'Редактировать' : 'Добавить'} категорию`)}
        />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <UiForm.Item
              name="name"
              label="Название"
              rules={[requiredRule]}
              extra={`Использовано ${getFieldValue('name').length}/50`}
            >
              <UiInput placeholder="Введите название" maxLength={MAX_LENGTH_INPUT_50} />
            </UiForm.Item>
          )}
        </UiForm.Item>
      </UiModal.Content>
      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiForm.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <UiButton
                type="primary"
                label={isEdit ? 'Сохранить' : 'Добавить'}
                onClick={form.submit}
                disabled={!getFieldValue('name')?.length}
              />
            )}
          </UiForm.Item>
          <UiButton type="tertiary" label="Отменить" onClick={onClose} />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
