import { RouterLink } from '@vkph/components';
import { UiAvatar, UiButton, UiCol, UiFlex, UiIcon, UiRow, useLayout } from '@vkph/ui';
import React, { FC, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { useSettingsConfig } from '@vkph/common/providers/settings-config';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import BurgerSvg from '@vkph/ui/svg/burger.svg';
import CloseSvg from '@vkph/ui/svg/close.svg';
import SearchSvg from '@vkph/ui/svg/search.svg';

import { HeaderActions } from '../actions';
import { HeaderEventsButton } from '../events-button';
import { HeaderLogo } from '../logo';
import { HeaderNotificationsButton } from '../notifications-button';

export const HeaderMobile: FC = () => {
  const navigate = useNavigate();
  const profile = useCurrentProfile();
  const { config } = useSettingsConfig();
  const { isNavbarCollapsed, toggleNavbar } = useLayout();
  const { spaceS } = useSpace();
  const isEventsButtonEnabled = !config.layouts?.headerEventsMeetingsDropdownButtonDisabled?.value;

  const handleToggleNavbar: MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    toggleNavbar?.();
  };

  const navigationIcon = (
    <UiIcon
      component={isNavbarCollapsed ? BurgerSvg : CloseSvg}
      color={variables.themePalette.white}
      width={20}
      height={20}
    />
  );

  return (
    <UiCol flex="auto">
      <UiRow justify="space-between" align="middle" wrap={false} style={{ marginLeft: 6 }} gutter={[8, 0]}>
        <UiFlex gap={spaceS}>
          <UiButton type="link" size="small" round icon={navigationIcon} onPointerDown={handleToggleNavbar} />
          <HeaderLogo />
        </UiFlex>
        <UiCol>
          <UiRow align="middle" wrap={false} gutter={[12, 0]}>
            {isEventsButtonEnabled && (
              <UiCol>
                <HeaderEventsButton type="link" size="small" />
              </UiCol>
            )}
            <UiCol>
              <UiButton
                type="link"
                size="small"
                round
                icon={
                  <UiIcon component={SearchSvg} width={20} height={20} color={variables.themePalette.white} />
                }
                onClick={() => navigate(getRoutePath(RouteNames.AdvancedSearch))}
              />
            </UiCol>
            <UiCol>
              <HeaderNotificationsButton type="link" size="small" />
            </UiCol>
            <UiCol>
              <RouterLink to={getRoutePath(RouteNames.Profile, { id: profile.id })}>
                <UiAvatar size="small" src={profile?.smallAvatar} bordered />
              </RouterLink>
            </UiCol>
            <UiCol>
              <HeaderActions />
            </UiCol>
          </UiRow>
        </UiCol>
      </UiRow>
    </UiCol>
  );
};
