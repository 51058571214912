import { Post, UiUser } from '@vkph/components';
import { UiButton, UiSpace, UiTypography, message } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, MouseEvent, useContext, useMemo, useState } from 'react';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { GlobalModalNames, openGlobalModal } from '@vkph/common/store/global-modals';
import { getSkillsStorage } from '@vkph/common/store/profile/skills';
import {
  SkillType,
  TimelineCompetenceContentModel,
  TimelineRecordTargetActorModel,
  TimelineSkill,
  TimelineSkillContentModel,
  TimelineTypes,
} from '@vkph/common/types/models';
import {
  RouteNames,
  getErrorResponseMessage,
  getFullNameWithoutPatronymic,
  getRoutePath,
} from '@vkph/common/utils';

import { isTimelineRecordModelSomeOfTypes } from '../../../types/guards/timeline';
import { TimelineCardGutterContext, TimelineCardProps } from '../TimelineCard';

type SkillData = {
  skillType: SkillType;
  info: TimelineSkill;
  description: string;
};

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record:
    | TimelineRecordTargetActorModel<TimelineSkillContentModel>
    | TimelineRecordTargetActorModel<TimelineCompetenceContentModel>;
}

export const TimelineCardSkill: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { target, publishedAt, createdAt, actor } = record;

  const { id: currentProfileId } = useCurrentProfile();

  const data = useMemo<SkillData>(() => {
    let cardSkillInfo: TimelineSkill;
    let cardType: SkillType;

    if (
      isTimelineRecordModelSomeOfTypes<TimelineCompetenceContentModel>(record, [
        TimelineTypes.CompetenceApproved,
      ])
    ) {
      cardType = SkillType.Competence;
      cardSkillInfo = record.content.userCompetence;
    } else {
      cardType = SkillType.Skill;
      cardSkillInfo = record.content.skill;
    }

    const skillName = cardType === SkillType.Competence ? 'компетенцию' : 'навык';

    return {
      info: cardSkillInfo,
      skillType: cardType,
      description: `подтвердил(а) ${skillName}`,
    };
  }, [record]);

  const { info, skillType, description } = data;

  const [isApproved, setIsApproved] = useState(() => {
    return info.approvers.some((approver) => approver.userFrom.id === currentProfileId);
  });

  const targetId = target.keycloakUser ? target.keycloakUser.keycloakId : '';

  const { approveSkillEffect } = useMemo(() => {
    return getSkillsStorage({ userId: info.ownerUserId, skillType });
  }, [info.ownerUserId, skillType]);

  const isApproveSkillLoading = useStore(approveSkillEffect.pending);

  const onApproveChange = (event: MouseEvent<HTMLButtonElement>) => {
    const isAccept = !isApproved;

    event.stopPropagation();

    approveSkillEffect({ skillId: info.skill.id, isAccept, skillType })
      .then(() => setIsApproved(isAccept))
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка смены статуса')));
  };

  const onSkillClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();

    openGlobalModal(GlobalModalNames.SkillsUsers, {
      userId: info.ownerUserId,
      skillType,
      userSkill: info,
    });
  };

  return (
    <Post style={{ paddingTop: verticalGutter }}>
      <Post.Header
        padding={[0, horizontalGutter]}
        author={actor}
        postDate={publishedAt || createdAt}
        extra={description}
      />
      <Post.Body padding={[16, 80]}>
        <UiButton.Decorator onClick={() => onClick?.(record)}>
          <UiSpace size={16} direction="vertical">
            <UiSpace size={8} direction="vertical">
              <UiUser.Info
                avatarProps={{ src: target.smallAvatar, size: 'small' }}
                title={getFullNameWithoutPatronymic(target)}
                onClick={(e: MouseEvent) => e.stopPropagation()}
                to={getRoutePath(RouteNames.Profile, { id: targetId })}
                isActive={Boolean(target.isActive || target.keycloakUser?.isActive)}
              />
              <UiTypography.Title level={2} style={{ margin: 0 }}>
                <UiTypography.Link
                  style={{ fontSize: 'inherit', lineHeight: 'inherit' }}
                  strong
                  onClick={onSkillClick}
                >
                  {info.skill.name}
                </UiTypography.Link>
              </UiTypography.Title>
            </UiSpace>
            <UiButton
              size="small"
              type={isApproved ? 'secondary' : 'primary'}
              label={isApproved ? 'Отменить' : 'Подтвердить'}
              onClick={onApproveChange}
              loading={isApproveSkillLoading}
              disabled={!actor.isActive || !target.isActive}
            />
          </UiSpace>
        </UiButton.Decorator>
      </Post.Body>
    </Post>
  );
};
