import { UiForm, UiDnD, dndClosesCenterCollision } from '@vkph/ui';
import map from 'lodash/map';
import React from 'react';

import { dndRestrictToVerticalAxis, dndVerticalListSortingStrategy } from '@vkph/ui/components/dnd/utils';

import { NavigationFavoritesActions } from './actions/NavigationFavoritesActions';
import { NavigationFavoritesHeader } from './header/NavigationFavoritesHeader';
import { NavigationFavoritesItem, CheckboxChangeEventHandlerCustom } from './item/NavigationFavoritesItem';

type Props = {
  selectedKeys: string[];
  isFavoritesEdit: boolean;
  isFavoritesEmpty: boolean;
  isSuccessEditLoading: boolean;
  isSuccessEditDisabled: boolean;
  onClickEditHandler: React.MouseEventHandler<HTMLElement>;
  onCancelEditHandler: React.MouseEventHandler<HTMLElement>;
  onSuccessEditHandler: React.MouseEventHandler<HTMLElement>;
  onCheckboxFavoritesChange: CheckboxChangeEventHandlerCustom;
};

export const NavigationFavorites: React.FC<Props> = (props) => {
  const {
    selectedKeys,
    isFavoritesEdit,
    isFavoritesEmpty,
    isSuccessEditLoading,
    isSuccessEditDisabled,
    onClickEditHandler,
    onCancelEditHandler,
    onSuccessEditHandler,
    onCheckboxFavoritesChange,
  } = props;

  return (
    <div>
      <NavigationFavoritesHeader
        isFavoritesEdit={isFavoritesEdit}
        isFavoritesEmpty={isFavoritesEmpty}
        onClickEditHandler={onClickEditHandler}
      />
      <UiForm.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <UiForm.List name="favorites">
            {(fields, { move }) => {
              const idsFavoritesDnDItems = map(getFieldValue(['favorites']), 'id');

              return (
                <UiDnD.Context
                  modifiers={[dndRestrictToVerticalAxis]}
                  collisionDetection={dndClosesCenterCollision}
                  onDragEnd={(result) => {
                    const activeIndex = result.active.data?.current?.sortable?.index;
                    const overIndex = result.over?.data?.current?.sortable?.index;

                    move(activeIndex, overIndex);
                  }}
                >
                  <UiDnD.Sortable.Context
                    items={idsFavoritesDnDItems}
                    strategy={dndVerticalListSortingStrategy}
                  >
                    {fields.map(({ key, name }) => {
                      return (
                        <UiForm.Item key={key} name={name} noStyle>
                          <NavigationFavoritesItem
                            index={key}
                            selectedKeys={selectedKeys}
                            isFavoritesEdit={isFavoritesEdit}
                            isSuccessEditLoading={isSuccessEditLoading}
                            isDragging={isFavoritesEdit}
                            onCheckboxFavoritesChange={onCheckboxFavoritesChange}
                          />
                        </UiForm.Item>
                      );
                    })}
                  </UiDnD.Sortable.Context>
                </UiDnD.Context>
              );
            }}
          </UiForm.List>
        )}
      </UiForm.Item>
      <NavigationFavoritesActions
        isFavoritesEdit={isFavoritesEdit}
        isFavoritesEmpty={isFavoritesEmpty}
        isSuccessEditLoading={isSuccessEditLoading}
        isSuccessEditDisabled={isSuccessEditDisabled}
        onClickEditHandler={onClickEditHandler}
        onCancelEditHandler={onCancelEditHandler}
        onSuccessEditHandler={onSuccessEditHandler}
      />
    </div>
  );
};
