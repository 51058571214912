import { UiTree, UiTreeProps } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './SiteNavbarMenu.scss';
import { SiteNavbarMenuLabel } from './label';
import { SiteNavbarMenuSkeleton } from './skeleton';

type SiteNavbarMenuComposition = {
  Label: typeof SiteNavbarMenuLabel;
  Skeleton: typeof SiteNavbarMenuSkeleton;
};

export interface SiteNavbarMenuProps extends UiTreeProps {
  loading?: boolean;
}

export const SiteNavbarMenu: FC<SiteNavbarMenuProps> & SiteNavbarMenuComposition = (props) => {
  const { loading, className, ...rest } = props;

  if (loading) {
    return <SiteNavbarMenuSkeleton />;
  }

  return <UiTree {...rest} className={classNames(styles.siteNavbarMenu, className)} />;
};

SiteNavbarMenu.Label = SiteNavbarMenuLabel;
SiteNavbarMenu.Skeleton = SiteNavbarMenuSkeleton;
