import { UiCard, UiList, UiSkeleton, UiSpace } from '@vkph/ui';
import React from 'react';

import { createArrayMock } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

const MOCKS_COUNT = 4;

const listItemsMock = createArrayMock(MOCKS_COUNT, (_, idx) => idx + 1);

export const SearchCategoryResultsMock = () => {
  const { space2XS, spaceXS } = useSpace();
  const itemPadding = usePaddingStyle([spaceXS, 0]);

  return (
    <UiCard size="default">
      <UiSpace full direction="vertical">
        <UiSkeleton loading width={150} height={20} />
        <UiList
          split={false}
          dataSource={listItemsMock}
          renderItem={() => (
            <UiList.Item style={itemPadding}>
              <UiSpace size={space2XS} direction="vertical" full>
                <UiSkeleton loading width={150} height={16} />
                <UiSkeleton loading width={200} height={16} />
              </UiSpace>
            </UiList.Item>
          )}
        />
      </UiSpace>
    </UiCard>
  );
};
