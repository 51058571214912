import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiButton, UiSpace, useSpace } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ListModel, NotificationModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<ListModel>;
}

export const NotificationList: FC<Props> = (props) => {
  const { notification } = props;
  const { createdAt, image, initiator, content } = notification;
  const { name, siteId, id } = content;

  const avatar = { src: image } satisfies UiAvatarProps;
  const { spaceS } = useSpace();
  const navigate = useNavigate();

  const notificationTitle = getFullNameWithoutPatronymic(initiator.fullName);
  const notificationDescription = getNotificationDescription(notification);

  const listPath = getRoutePath(RouteNames.AdminContentManagementSiteListEdit, {
    id: siteId,
    listId: id,
  });

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: initiator.keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={initiator.isActive}
      />
      <Notification.Body>
        <UiSpace full size={spaceS} direction="vertical">
          <RouterLink to={listPath}>{name}</RouterLink>
          <UiButton label="Посмотреть" type="secondary" onClick={() => navigate(listPath)} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
