import { ActionsDropdown } from '@vkph/components';
import {
  notification,
  UiButton,
  UiDivider,
  UiFlex,
  UiIcon,
  UiList,
  UiModal,
  UiSpace,
  UiTag,
  UiTypography,
  useModalBase,
  usePaddingStyle,
  useSpace,
} from '@vkph/ui';
import React, { FC, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  closeGlobalModal,
  GlobalModalNames,
  GlobalModalsStorePayloads,
  openGlobalModal,
} from '@vkph/common/store/global-modals';
import { OrderingParams } from '@vkph/common/types';
import { FileStorageCategoryModel } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import AddCircleSVG from '@vkph/ui/svg/add-circle.svg';
import CloseSVG from '@vkph/ui/svg/close.svg';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import EditSvg from '@vkph/ui/svg/edit.svg';
import SortingDownSvg from '@vkph/ui/svg/sorting-down.svg';
import SortingUpSvg from '@vkph/ui/svg/sorting-up.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.FileStorageCategoryList]['payload']>;

export const FileStorageCategoryListModal: FC<Props> = (props) => {
  const { onClose, categoriesStorage, entryId } = props;
  const {
    storage: fileStorageCategoriesStorage,
    createFileStorageCategoryEffect,
    updateFileStorageCategoryEffect,
    deleteFileStorageCategoryEffect,
  } = categoriesStorage;

  const [sortDirection, setSortDirection] = useState<OrderingParams>(OrderingParams.NameAtAsc);
  const { spaceS, spaceL, spaceXL } = useSpace();
  const paddingStyle = usePaddingStyle([spaceL, spaceXL]);
  const { warning } = useModalBase();

  const { data: fileStorageCategoryData } = useAbstractStorage(fileStorageCategoriesStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const onAddCategory = () => {
    openGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate, {
      entryId,
      onSuccess: createFileStorageCategoryEffect,
      onClose: () => closeGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate),
    });
  };

  const onEdit = (item: FileStorageCategoryModel) => {
    openGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate, {
      entryId,
      category: item,
      onSuccess: (category) => {
        updateFileStorageCategoryEffect(category);
      },
      onClose: () => closeGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate),
    });
  };

  const onDelete = (item: FileStorageCategoryModel) => {
    warning({
      title: `Удалить категорию?`,
      content: `Вы действительно хотите удалить категорию "${item.name}" без возможности восстановления?`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: (close) => {
        deleteFileStorageCategoryEffect({ categoryId: item.id })
          .then(() => {
            close();
            notification.success({ message: 'Категория успешно удалена' });
          })
          .catch((e) =>
            notification.error({ message: getErrorResponseMessage(e, `Ошибка удаления категории`) }),
          );
      },
    });
  };

  if (sortDirection === OrderingParams.NameAtAsc) {
    fileStorageCategoryData.sort((a, b) => a.name.localeCompare(b.name));
  } else {
    fileStorageCategoryData.sort((a, b) => b.name.localeCompare(a.name));
  }

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          style={{ justifyContent: 'space-between' }}
          steps={getModalStepsForSingleTitle('Категории')}
          noCloseIcon
        >
          <UiSpace size={spaceXL}>
            <UiButton
              type="link"
              label="Добавить"
              icon={<UiIcon component={AddCircleSVG} height={20} width={20} />}
              onClick={onAddCategory}
            />
            <UiButton size="middle" type="link-secondary" onClick={onClose} icon={<CloseSVG />} />
          </UiSpace>
        </UiModal.Header.Title>
      </UiModal.Header>
      <UiModal.Content>
        <UiSpace size={spaceS} style={paddingStyle}>
          <UiTypography.Text strong>Сортировка</UiTypography.Text>
          <ActionsDropdown
            items={[
              {
                label: 'От А до Я',
                onClick: () => setSortDirection(OrderingParams.NameAtAsc),
              },
              {
                label: 'От Я до А',
                onClick: () => setSortDirection(OrderingParams.NameAtDesc),
              },
            ]}
            icon={sortDirection === OrderingParams.NameAtAsc ? SortingDownSvg : SortingUpSvg}
          />
        </UiSpace>
        <UiDivider emptyMargin />
        <UiList
          itemLayout="vertical"
          dataSource={fileStorageCategoryData}
          renderItem={(item) => (
            <UiList.Item style={paddingStyle}>
              <UiFlex justify="space-between" align="center">
                <UiTag title={item.name} color={variables.themePalette.colorTagSilver} />
                <ActionsDropdown
                  items={[
                    { icon: EditSvg, label: 'Редактировать', onClick: () => onEdit(item) },
                    {
                      icon: DeleteSvg,
                      label: 'Удалить',
                      onClick: () => onDelete(item),
                    },
                  ]}
                />
              </UiFlex>
            </UiList.Item>
          )}
        />
      </UiModal.Content>
    </>
  );
};
