import {
  UiButton,
  UiForm,
  UiIcon,
  message,
  UiTypography,
  UiUploadOriginFile,
  UiUpload,
  UiUploadProps,
  UiUploadFileType,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, useState, useEffect } from 'react';

import { AddAttachmentParams } from '@vkph/common/store/filestorage';
import { FileId, FileStorageEntryId } from '@vkph/common/types/models';
import { UiUploadFileAttach } from '@vkph/common/types/uploadsFormItem';
import { checkFileSizeUploadEffect } from '@vkph/common/utils';
import ClearSVG from '@vkph/ui/svg/clear.svg';

import { UploadsFormListItem } from '../list-item/UploadsFormListItem';
import styles from './UploadsFormItem.scss';

const MAX_FILES_COUNT = 10;
const MAX_FILES_TOTAL_SIZE = 10;

export interface UploadsFormItemProps extends UiUploadProps {
  name: string;
  buttonLabel: string;
  formItemLabel?: string;
  uploadFileType: UiUploadFileType;
  fileSizeText?: string;
  maxTotalSize?: number;
  maxCount?: number;
  disabled?: boolean;
  formItemClassName?: string;
  onAddAttachment?: ({ id, size, name, url }: AddAttachmentParams) => void;
}

const getFileListFromEvent = (e: { fileList: UiUploadFileAttach<FileId | FileStorageEntryId> }) => e.fileList;

export const UploadsFormItem: FC<UploadsFormItemProps> = (props) => {
  const {
    name,
    buttonLabel,
    formItemLabel,
    disabled = false,
    uploadFileType,
    fileSizeText,
    maxTotalSize = MAX_FILES_TOTAL_SIZE,
    maxCount = MAX_FILES_COUNT,
    formItemClassName,
    onAddAttachment,
    multiple = true,
    style,
    ...restProps
  } = props;

  const [maxAmountError, setMaxAmountError] = useState(false);
  const [maxTotalSizeError, setMaxTotalSizeError] = useState(false);

  const clearIcon = <UiIcon component={ClearSVG} width={20} height={20} />;

  useEffect(() => {
    if (maxAmountError) {
      message.error(`Общее количество файлов должно быть не больше ${maxCount}`);
      setMaxAmountError(false);
    }

    if (maxTotalSizeError) {
      message.error(`Общий вес файлов должен быть не больше ${maxTotalSize}Мб`);
      setMaxTotalSizeError(false);
    }
  }, [maxAmountError, maxTotalSizeError]);

  const onBeforeUpload = async (file: UiUploadOriginFile, fileList: UiUploadOriginFile[]) => {
    await checkFileSizeUploadEffect({ file }).catch((e) => {
      message.error(`${file.name}. ${e}`);
      return UiUpload.LIST_IGNORE;
    });

    if (!maxAmountError && maxCount && fileList.length > maxCount) {
      setMaxAmountError(true);
      return UiUpload.LIST_IGNORE;
    }

    if (maxTotalSize) {
      const summarySize = fileList.reduce((acc, currentFile) => acc + currentFile.size, 0);
      const isLessThanMaxSize = summarySize / 1024 / 1024 < maxTotalSize;

      if (!isLessThanMaxSize && !maxTotalSizeError) {
        setMaxTotalSizeError(true);
      }

      return isLessThanMaxSize || UiUpload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <UiForm.Item
      name={name}
      valuePropName="fileList"
      getValueFromEvent={getFileListFromEvent}
      className={classNames(styles.uploadsFormItem, formItemClassName)}
      label={formItemLabel}
      style={style}
    >
      <UiUpload
        multiple={multiple}
        maxCount={maxCount}
        showUploadList={{ showRemoveIcon: true, removeIcon: clearIcon }}
        beforeUpload={onBeforeUpload}
        itemRender={(...params) => <UploadsFormListItem itemRenderParams={params} type={uploadFileType} />}
        disabled={disabled}
        {...restProps}
      >
        <UiButton type="link" label={buttonLabel} disabled={disabled} />
        {fileSizeText && (
          <UiTypography.Footnote type="secondary" style={{ display: 'block', margin: '16px 0' }}>
            {fileSizeText}
          </UiTypography.Footnote>
        )}
      </UiUpload>
    </UiForm.Item>
  );
};
