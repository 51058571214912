import { UiCol, UiRow, UiSkeleton, useBreakpoint, useLayout } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { NewsCategoriesDictStorage, NewsFeedStorage } from '@vkph/common/store/news';
import { createArrayMock } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { NewsPinnedItem } from '../item/NewsPinnedItem';

const mocks = createArrayMock(6, (_, index) => <UiSkeleton key={index} loading width="100%" height="100%" />);

type Props = {
  newsFeedStorage: NewsFeedStorage;
  newsCategoriesStorage: NewsCategoriesDictStorage;
};

export const NewsPinnedItems: FC<Props> = (props) => {
  const { newsFeedStorage, newsCategoriesStorage } = props;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { outerGutter } = useLayout();
  const { space2XS, spaceM } = useSpace();

  const { data: newsFeedData } = useStore(newsFeedStorage.storage.store);
  const isNewsFeedLoading = useStore(newsFeedStorage.storage.fetchEffect.pending);
  const { data: newsCategoriesData } = useStore(newsCategoriesStorage.storage.store);
  const isNewsCategoriesLoading = useStore(newsCategoriesStorage.storage.fetchEffect.pending);

  const isLoading = isNewsFeedLoading || isNewsCategoriesLoading;

  const [superCoverNewsArticle, ...newsArticleData] = newsFeedData;
  const isSuperCover = Boolean(superCoverNewsArticle?.isSuperCover);

  const isShowSuperCover = isSuperCover && isLayoutLarge;

  return (
    <UiRow
      wrap={isShowSuperCover}
      gutter={[space2XS, space2XS]}
      style={{ overflowX: 'auto' }}
      padding={[0, isLayoutLarge ? outerGutter : spaceM]}
    >
      {isShowSuperCover && (
        <>
          <UiCol span={12} style={{ aspectRatio: 16 / 9 }}>
            <NewsPinnedItem newsArticle={superCoverNewsArticle} newsCategoriesMap={newsCategoriesData} />
          </UiCol>
          <UiCol span={12}>
            <UiRow wrap gutter={[space2XS, space2XS]} style={{ width: '100%' }}>
              {isLoading && mocks}
              {!isLoading &&
                newsArticleData.map((item) => (
                  <UiCol span={12} key={item.id} style={{ aspectRatio: 16 / 9 }}>
                    <NewsPinnedItem newsArticle={item} newsCategoriesMap={newsCategoriesData} />
                  </UiCol>
                ))}
            </UiRow>
          </UiCol>
        </>
      )}
      <>
        {isLoading && mocks}
        {!isLoading &&
          !isShowSuperCover &&
          newsFeedData.length > 0 &&
          newsFeedData.map((item) => (
            <UiCol
              key={item.id}
              span={4}
              style={{
                minWidth: 320,
                height: 175,
              }}
            >
              <NewsPinnedItem newsArticle={item} newsCategoriesMap={newsCategoriesData} />
            </UiCol>
          ))}
      </>
    </UiRow>
  );
};
