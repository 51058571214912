import { UiImageBackground, UiOverlay, UiOverlayTypes } from '@vkph/ui';
import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import { useTheme } from '@vkph/ui/providers/theme';

const EVENT_IMAGE_HEIGHT = 460;

type Props = {
  image?: string;
  standalone?: boolean;
};

export const EventImage: FC<PropsWithChildren<Props>> = (props) => {
  const { image, children, standalone } = props;
  const [{ variables: themeVariables }] = useTheme();

  const overlayStyles: CSSProperties = {
    height: EVENT_IMAGE_HEIGHT,
    color: themeVariables.colorTextOnBrand,
  };
  const backgroundImageStyles: CSSProperties = {
    height: EVENT_IMAGE_HEIGHT,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <UiOverlay
      type={UiOverlayTypes.Primary}
      content={children}
      style={overlayStyles}
      trigger={standalone ? 'hover' : undefined}
      behavior="scale"
    >
      <UiImageBackground style={backgroundImageStyles} url={image || ''} />
    </UiOverlay>
  );
};
